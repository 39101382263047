import React, { Component, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Switch from 'react-router-dom/Switch';
import Route from 'react-router-dom/Route';
import Redirect from 'react-router-dom/Redirect';
import { ConnectedRouter } from 'react-router-redux';
import ProtectedRoute from '../HOC/PrivateRoute';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Intercom from 'react-intercom';
import { IntlProvider, addLocaleData, FormattedMessage } from 'react-intl';
import queryString from 'query-string';

import { changeLang } from '../actions/intl';
import { logoutUser } from '../actions/auth';
import { hideVerifyAccModal } from '../modules/Dashboard/actions';
import { Loader2 } from '../components/loader';
import en from 'react-intl/locale-data/en';
import fr from 'react-intl/locale-data/fr';
import sw from 'react-intl/locale-data/sw';
// Our translated strings
import localeData from '../intl/locales/data.json';

import { API_URL, INTERCOM_APP_ID } from '../utils/constants';
import chromeLogo from '../images/svgs/chrome_logo.svg';
import firefoxLogo from '../images/svgs/firefox_logo.svg';
import safariLogo from '../images/svgs/safari_logo.svg';

import Dialog from 'material-ui/Dialog';
import { Button } from 'antd';
import RootModal from '../modals/RootModal';

// import ResetVerify from '../modules/Auth/containers/ResetVerify';
// import VerifyReset from '../modules/Auth/containers/VerifyReset';
import { getCookie, setCookie } from '../utils/helpers';
addLocaleData([...en, ...fr, ...sw]);

const languages = navigator.languages || 'en';

// const Landing = lazy(() => import('../modules/Landing'));
const ExternalHome = lazy(() => import('../modules/ExternalHome'));
const PrivacyPolicy = lazy(() => import('../modules/PrivacyPolicy'));
const FAQ = lazy(() => import('../modules/FAQ'));
const Dashboard = lazy(() => import('../layouts/Dashboard'));
// const Login = lazy(() => import('../modules/Auth/containers/Login'));
// const Main = lazy(() => import('../modules/Auth/containers/Main'));
// const SignUp = lazy(() => import('../modules/Auth/containers/Signup'));
// const ForgotPassword = lazy(() => import('../modules/Auth/containers/ForgotPassword'));
// const VerifyAccount = lazy(() => import('../modules/Dashboard/container/VerifyAccount'));
// const InviteVerify = lazy(() => import('../modules/Auth/containers/InviteVerify'));

// Render our root component into the div with id "root"
// We select the messages to pass to IntlProvider based on the user's locale

class App extends Component {

  state = {
    proceed: false, auth: false,
  };

  componentWillMount() {
    var path = window.location.pathname.split('/')[2];

    if (['signup', 'signin', 'login', 'forgotten-password', 'resetverify', 'verifyaccount'].includes(path) === true) {
      this.props.logoutUser();
      // const lang = window.location.pathname.split('/')[1] || 'en';
      // window.location.assign(`${process.env.REACT_APP_SSO_WEB_URL}/${lang}/signout?continue=${window.location.protocol}//${window.location.host}/${lang}/forms`);
      // window.location.assign(`${process.env.REACT_APP_SSO_WEB_URL}/${lang}/signout`);
      return false;
    } else {
      this.setState({ proceed: true });
    }
  }

  detectBrowser = () => {
    let ua = navigator.userAgent.match(
      /(opera|chrome|safari|firefox|msie|mobile)\/?\s*(\.?\d+(\.\d+)*)/i
    ), // eslint-disable-next-line
      browser;

    if (navigator.userAgent.match(/Edge/i) || navigator.userAgent.match(/Trident.*rv[ :]*11\./i)) {
      return (browser = 'msie');
    } else if (ua) {
      return (browser = ua[1].toLowerCase());
    } else {
      return (browser = 'unsupported');
    }
  };

  componentDidMount() {
    if (getCookie('site_lang')) {
      this.props.changeLang(getCookie('site_lang'));
    } else if (languages.includes('sw')) {
      this.props.changeLang('sw');
    } else if (languages.includes('fr')) {
      this.props.changeLang('fr');
    } else if (languages.includes('en')) {
      this.props.changeLang('en');
    } else {
      axios({
        method: 'post',
        url: `${API_URL}/ipinfo`
      }).then(info => {
        this.props.changeLang(((info.data.location.languages || [])[0] || {}).code || 'en');
      }).catch(error => {
        throw new Error(error);
      });
    }

    let qs = queryString.parse(this.props.history.location.search);
    qs.debugMode && setCookie('debugMode', qs.debugMode === 'true');
  }

  handleVerifyAccountModalClose = () => {
    this.props.hideVerifyAccModal();
  };

  IsStringJSON = str => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  render() {
    const actions = [
      <Button onClick={this.handleVerifyAccountModalClose} type="primary">
        <FormattedMessage defaultMessage={'Later'} id="App.LaterText" />
      </Button>
    ];
    const { history, lang, loading_account } = this.props;
    const browserType = this.detectBrowser();

    const appUser = this.IsStringJSON(getCookie('user')) ? JSON.parse(getCookie('user')) || {} : {};

    if (['safari', 'firefox', 'chrome', 'mobile', 'msie'].includes(browserType) === false) {
      return (
        <div style={{ padding: '4rem', minHeight: '100vh' }}>
          <div style={{ marginBottom: '3rem' }}>
            <h2>Insyt - Data Collection</h2>
            <h5>Conduct surveys from everywhere</h5>
          </div>

          <div>
            <p style={{ marginBottom: '2rem' }}>
              We recommend using <b>Insyt</b> with one of the following browsers:
            </p>
            <div>
              <a
                href="https://www.google.com/chrome/"
                style={{ marginRight: '1.5rem', color: 'black' }}
              >
                <img
                  src={chromeLogo}
                  width="70"
                  style={{ marginRight: '.5rem' }}
                  alt="chrome"
                />
                Google Chrome
              </a>
              <a
                href="https://www.mozilla.org/en-US/firefox/new/"
                style={{ marginRight: '1.5rem', color: 'black' }}
              >
                <img
                  src={firefoxLogo}
                  width="70"
                  style={{ marginRight: '.5rem' }}
                  alt="firefox"
                />
                Mozilla Firefox
              </a>
              <a
                href="https://support.apple.com/downloads/safari"
                style={{ marginRight: '1.5rem', color: 'black' }}
              >
                <img
                  src={safariLogo}
                  width="70"
                  style={{ marginRight: '.5rem' }}
                  alt="safari"
                />
                Safari
              </a>
            </div>
          </div>
        </div>
      );
    } else if (!loading_account && this.state.proceed) {
      return (
        <IntlProvider locale={lang} messages={localeData[lang]}>
          <ConnectedRouter history={history}>
            <MuiThemeProvider>
              <div>
                {this.props.showAppInfoCard && (
                  <div className="AppInfoCard">
                    <div className="AppInfoCard__Header">
                      <p><FormattedMessage id="App.Text.PreparingDownload" defaultMessage="Preparing download" /></p>
                    </div>
                    <div className="AppInfoCard__Content">
                      <span>{this.props.AppInfoCard}</span>
                    </div>
                  </div>
                )}

                <Intercom appID={INTERCOM_APP_ID} {...appUser} />
                <RootModal />
                <Dialog
                  style={{
                    zIndex: '3000'
                  }}
                  actions={actions}
                  modal={true}
                  open={this.props.showVerifyModal}
                  onRequestClose={this.handleVerifyAccountModalClose}
                >
                  <div
                    style={{
                      borderLeft: '5px solid #f36a19',
                      paddingLeft: '30px',
                      margin: '.5rem 0 1.5rem 0'
                    }}
                  >
                    <h2>
                      <FormattedMessage
                        defaultMessage={'Verify your email'}
                        id="App.VerifyEmail.Header"
                      />
                    </h2>
                  </div>
                  <p>
                    <FormattedMessage
                      id="App.VerifyEmail.Msg0"
                      defaultMessage={'We just sent an email to {email}'}
                      values={{
                        email: (
                          <strong>
                            {this.props.user ? this.props.user.email : ''}
                          </strong>
                        )
                      }}
                    />
                  </p>

                  <p>
                    <FormattedMessage
                      id="App.VerifyEmail.Msg1"
                      defaultMessage={
                        'We need to verify that this email address belongs to you. Check your email and follow the instructions in the message we sent you'
                      }
                    />
                  </p>

                  <p className="font-italic font-weight-bold">
                    <FormattedMessage
                      id="App.VerifyEmail.Msg2"
                      defaultMessage={
                        'If you do not see a message in your inbox, make sure the email address listed above is correct and check your spam or junk mail folder. This email is sent from {email}'
                      }
                      values={{
                        email: <strong>support@esoko.com</strong>
                      }}
                    />
                  </p>
                </Dialog>

                <Suspense fallback={<Loader2 />}>
                  <Switch>
                    <Route
                      exact
                      path="/:lang/home"
                      render={rest => <ExternalHome {...rest} />}
                    />
                    <Route
                      exact
                      path="/:lang/privacy-policy"
                      render={rest => (
                        <PrivacyPolicy {...rest} {...this.props} />
                      )}
                    />
                    <Route
                      exact
                      path="/:lang/faq"
                      render={rest => <FAQ {...rest} {...this.props} />}
                    />

                    <Route
                      exact
                      path="/:lang/site"
                      render={rest => <ExternalHome {...rest} />}
                    />
                    {/* <Route
                      path="/:lang/login"
                      render={rest => <Login {...rest} {...this.props} />}
                    />
                    <Route
                      path="/:lang/signup"
                      render={rest => <SignUp {...rest} {...this.props} />}
                    />
                    <Route
                      path="/:lang/forgotten-password"
                      render={rest => (
                        <ForgotPassword {...rest} {...this.props} />
                      )}
                    />
                    <Route
                      path="/:lang/resetverify/:resetToken"
                      render={rest => <VerifyReset {...rest} {...this.props} />}
                    />
                    <Route
                      path="/:lang/verifyaccount/:vacctoken"
                      render={rest => (
                        <VerifyAccount {...rest} {...this.props} />
                      )}
                    />
                    <Route
                      path="/:lang/invites/:inviteToken"
                      render={rest => (
                        <InviteVerify {...rest} {...this.props} />
                      )}
                    /> */}

                    <ProtectedRoute path="/:lang/" component={Dashboard} />

                    <Redirect to={getCookie('user_token') ? `/${this.props.lang}/forms` : `/${this.props.lang}/home`} />
                  </Switch>
                </Suspense>
              </div>
            </MuiThemeProvider>
          </ConnectedRouter>
        </IntlProvider>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = state => {
  return {
    lang: state.intl.lang,
    isAuthenticated: state.auth.isAuthenticated,
    showVerifyModal: state.dashboard.showVerifyModal,
    showAppInfoCard: state.util.showAppInfoCard,
    AppInfoCard: state.util.AppInfoCard,
    review_exercises: state.review_exercises.review_exercises
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeLang: lang => {
      dispatch(changeLang(lang));
    },
    logoutUser: () => {
      dispatch(logoutUser());
    },
    hideVerifyAccModal: () => {
      dispatch(hideVerifyAccModal());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
