import React from 'react';
import { Button, Modal } from 'antd';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { hideModal, showModal } from '../actions/utils';
import BankSVG from '../images/svgs/bank.svg';

const BankDepDetails = props => {
  return (
    <Modal
      centered
      title={
        <p style={{ fontWeight: 'bold' }}>
          {' '}
          <FormattedMessage
            defaultMessage="Bank deposit details"
            id="App.BankDepDetailsModal.Title"
          />
        </p>
      }
      // className="billingInfoModal"
      destroyOnClose
      visible={props.modalShow}
      onCancel={() => props.hideModal()}
      footer={
        <Button type="link" onClick={() => props.hideModal()}>
          <FormattedMessage defaultMessage="Close" id="App.Text.Close" />
        </Button>
      }
    >
      <div>
        <p>
          <FormattedMessage id="Billing.Accept.Bank1" defaultMessage="We accept payments through bank transfers into the Zenith bank account stated below. Send an email to" />{' '}
          <a href="mailto:support@esoko.com?Subject=Payment%20support">
            support@esoko.com
          </a>{' '}
          <FormattedMessage id="Billing.Accept.Bank2" defaultMessage="after payment with your phone number or email address and payment details for us to confirm your transaction" />
        </p>

        <div className="BankDepDetails">
          <img src={BankSVG} alt="bank" />
          <div>
            Bank Name: Zenith Bank
            <br />
            Account Name: Esoko Limited
            <br />
            Account Number: 0006012400112 (GHS)
            <br />
            Account Number: 0004052400017 (USD)
            <br />
            Branch: Trade Fair <br />
            Swift Code: ZEBLGHAC
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapDispatch = dispatch => {
  return {
    hideModal: () => {
      dispatch(hideModal());
    },
    showModal: (modalName, modalProps) => {
      dispatch(showModal(modalName, modalProps));
    }
  };
};

export default connect(
  null,
  mapDispatch
)(BankDepDetails);
