import { setCookie } from '../utils/helpers';

export const changeLang = lang => {
  if (lang !== 'en' && lang !== 'sw' && lang !== 'fr' && lang === undefined) {
    setCookie('site_lang', 'en');
    return dispatch => {
      dispatch({ type: 'CHANGE_LANG', lang: 'en' });
    };
  } else {
    setCookie('site_lang', lang);
    return dispatch => {
      dispatch({ type: 'CHANGE_LANG', lang });
    };
  }
};

export const changeLangInSettings = lang => {
  if (lang !== 'en' && lang !== 'sw' && lang !== 'fr') {
    setCookie('site_lang', 'en');
    return dispatch => {
      dispatch({ type: 'CHANGE_LANG_IN_SETTINGS', lang: 'en' });
    };
  } else {
    setCookie('site_lang', lang);
    return dispatch => {
      dispatch({ type: 'CHANGE_LANG_IN_SETTINGS', lang });
    };
  }
};
