import axios from 'axios';
// import React from 'react';
import base64 from 'base-64';
import {
  LOGGING_IN,
  LOGGING_IN_FAILED,
  LOGGING_IN_SUCCESS,
  FETCHING_USER,
  FETCHING_USER_SUCCESS,
  FETCHING_USER_FAILED,
  UPDATING_USER,
  UPDATING_USER_SUCCESS,
  LOGGING_OUT,
  LOGGING_OUT_SUCCESS,
  CREATING_ACCOUNT,
  CREATING_ACCOUNT_SUCCESS,
  CREATING_ACCOUNT_FAILED,
  RESETTING_PASSWORD,
  RESETTING_PASSWORD_SUCCESS,
  RESETTING_PASSWORD_FAILED,
  VALIDATING_TOKEN,
  VALIDATING_TOKEN_SUCCESS,
  VALIDATING_TOKEN_FAILED,
  UPDATING_PROJECT_DETAILS,
  UPDATING_PROJECT_DETAILS_SUCCESS,
  DELETING_SURVEY_RESPONSES_FAILED,
  UPDATING_USER_FAILED
} from '../utils/types';
import { notification } from 'antd';
import { replace } from 'react-router-redux';
// import { FormattedMessage } from 'react-intl';

import {
  API_URL,
  REACT_APP_VERSION,
  REACT_APP_ANALYTICS_APP_URL,
  REACT_APP_ABIS_APP_URL
} from '../utils/constants';
import { sendMetric } from './prom';
import messages from '../utils/commonwords';
import axiosTiming from '../utils/axios-timing';
import {
  setCookie,
  getCookie,
  delCookie,
  rerouteUser,
  hasP,
  isJson
} from '../utils/helpers';

const logout = () => {
  delCookie('user_token', getCookie('user_token'));
  delCookie('user', getCookie('user'));
  delCookie('project_id', getCookie('project_id'));
  delCookie('user');
  delCookie('user_id');
  delCookie('survey_id');
  delCookie('project_id');
};

const appTable = {
  InsytAnalytics: REACT_APP_ANALYTICS_APP_URL,
  InsytAbis: REACT_APP_ABIS_APP_URL
};

export const setLoginStorage = (user) => {
  const old = getCookie('user') || {};
  return JSON.parse(old);
  // let ssoUser = {
  //   user_id: user.uuid,
  //   first_name: user.first_name,
  //   last_name: user.last_name,
  //   email: user.email,
  //   msisdn: user.msisdn,
  //   account_id: user.project_uuid || user.project_id,
  //   role: user.role,
  //   user_preferences: user.user_preferences || {},
  //   status: user.status,
  //   signup_country: user.signup_country,
  //   profile_image: old.profile_image || API_URL + '/users/images/' + user.photo_id
  // };
  // setCookie('user', JSON.stringify(ssoUser), 7);
  // setCookie('user_id', ssoUser.user_id);
  // if (!getCookie('project_id')) {
  //   // setCookie('project_id', ssoUser.account_id);
  // }
  // setCookie('user_name', ssoUser.first_name);
  // return ssoUser;
}

export const resetErrorState = () => {
  return dispatch => [
    dispatch({
      type: 'RESET_ERROR_MSGS'
    })
  ];
};

export const loginUser = (intl, userData) => {
  const loginData = `Basic ${base64.encode(userData.email + ':' + userData.password)}`;

  return dispatch => {
    dispatch({
      type: LOGGING_IN
    });
    dispatch(
      sendMetric(
        'increment',
        `Web`,
        'This metric is to measure the total number of requests made for login',
        {
          section: 'Login Request',
          event: 'Total',
          version: `${REACT_APP_VERSION}`
        }
      )
    );
    axios({
      method: 'post',
      url: `${API_URL}/auth/login`,
      metricState: {
        name: 'login'
      },
      headers: {
        Authorization: loginData
      },
      custMetric: {
        metricType: 'timing',
        metric: 'web_timer',
        help: 'This metric measures the duration of the login request',
        labels: {
          section: 'Login Request',
          event: 'duration',
          version: `${REACT_APP_VERSION}`
        }
      }
    }).then(response => {
      if (response.data.statusCode === 200) {
        const user = setLoginStorage(response.data.result);
        const disallowed = hasP('ACCESS_INSYT', user.role);

        setCookie('user_token', response.data.token, 7);

        dispatch({ type: LOGGING_IN_SUCCESS, responseData: user });

        if (user && disallowed === false) {
          return rerouteUser(user);
        }

        if (
          !!getCookie('appReferrerId') &&
          !!appTable[`${getCookie('appReferrerId')}`]
        ) {
          let tolkein = getCookie('appReferrerId');
          delCookie('appReferrerId');
          window.location.assign(`${appTable[`${tolkein}`]}/?userAuthId=${response.data.token}`);
        } else {
          dispatch(replace(`/${getCookie('site_lang') || 'en'}/forms`));
        }
      } else if (
        response.data.statusCode >= 500 &&
        response.data.statusCode < 600
      ) {
        dispatch(
          sendMetric(
            'increment',
            `Web`,
            'This metric is to measure the number of failed requests made for login',
            {
              section: 'Login Request',
              event: 'Failed',
              version: `${REACT_APP_VERSION}`
            }
          )
        );
        dispatch({
          type: LOGGING_IN_FAILED
        });
      } else if (
        response.data.statusCode === 404 ||
        response.data.statusCode === 400
      ) {
        dispatch({
          type: LOGGING_IN_FAILED
        });
        notification.error({
          message: intl.formatMessage(messages.invalidCred)
        });
      } else if (response.data.statusCode === 410) {
        dispatch({
          type: LOGGING_IN_FAILED
        });
        notification.info({
          message: intl.formatMessage(messages.bruteForcePolice)
        });
      } else {
        dispatch({
          type: LOGGING_IN_FAILED,
          error_msg: response.data.message
        });
      }
    }).catch(error => {
      dispatch({
        type: LOGGING_IN_FAILED,
        error_msg: 'No internet connection'
      });
      dispatch(
        sendMetric(
          'increment',
          `Web`,
          'This metric is to measure the number of unreachable server errors on login',
          {
            section: 'Login Request',
            event: 'Unreachable',
            version: `${REACT_APP_VERSION}`
          }
        )
      );
      notification.error({
        message: intl.formatMessage(messages.networkError)
      });
    });

    axiosTiming(axios, console.log);
  };
};

export const logoutUser = () => {
  return dispatch => {
    // dispatch({ type: LOGGING_IN_FAILED });
    const lang = window.location.pathname.split('/')[1] || 'en';
    window.location = `${process.env.REACT_APP_SSO_WEB_URL}/${lang}/signout`;
    // window.location.assign(`${process.env.REACT_APP_SSO_WEB_URL}/${lang}/signout?continue=${encodeURI(window.location.href)}`);
  }

  // return dispatch => { console.log('123');
  //   dispatch({ type: LOGGING_OUT });
  //   new Promise((resolve) => {
  //     resolve(logout());
  //   }).then(() => { console.log('345');
  //     dispatch(
  //       replace(`/${getCookie('site_lang') || 'en'}/login`)
  //     );
  //     dispatch({ type: LOGGING_OUT_SUCCESS });
  //   }).catch(error => {
  //     console.log('678');
  //     throw new Error(error);
  //   });
  // };
  // try {
  //   await new Promise(resolve => {
  //     resolve(logout());
  //   });
  //   notification.warning({
  //     message: (
  //       <FormattedMessage
  //         id="Notification.errorToken"
  //         defaultMessage="You are now logged out"
  //       />
  //     )
  //   });
  //   window.location.replace(`/${getCookie('site_lang') || 'en'}/login`);
  //   // dispatch({ type: LOGGING_OUT_SUCCESS });
  // } catch (error) {
  //   throw new Error(error);
  // }
};

export const fetchUser = userId => {
  let randomVal = Date.now();
  return dispatch => {
    dispatch({ type: FETCHING_USER });
    try {
      let userCookie = JSON.parse(getCookie('user'));
      return axios({
        method: 'get',
        // url: `${API_URL}/users/${userId}/?random=${randomVal}`,
        url: `${API_URL}/users/${userCookie.user_id}/uuid/?random=${randomVal}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-access-token': `${getCookie('user_token')}`
        }
      }).then(response => {
        let user = response.data.data;
        const disallowed = user ? hasP('ACCESS_INSYT', user.role) : true;
        if (response.data.statusCode === 200) {
          user = isJson(getCookie('user')) ? JSON.parse(getCookie('user')) : {};
          dispatch({ type: FETCHING_USER_SUCCESS, user });
          setLoginStorage(user);
          if (user && disallowed === false) {
            return rerouteUser(user);
          }
          return user;
        } else {
          // dispatch({ type: FETCHING_USER_FAILED });
          // new Promise((resolve, reject) => {
          //   dispatch({ type: LOGGING_OUT });
          //   resolve(logout());
          // }).then(() => {
          //   dispatch(replace(`/${getCookie('site_lang') || 'en'}/home`));
          //   dispatch({ type: LOGGING_OUT_SUCCESS });
          // });
        }
      }).catch(err => {
        dispatch({ type: FETCHING_USER_FAILED });
      });
    } catch (e) {
      dispatch({ type: FETCHING_USER_FAILED });
      return new Promise((resolve, reject) => {
        dispatch({ type: LOGGING_OUT });
        resolve(logout());
      }).then(() => {
        dispatch(replace(`/${getCookie('site_lang') || 'en'}/home`));
        dispatch({ type: LOGGING_OUT_SUCCESS });
      });
    }
  };
};

export const updateUser = (intl, userData, file, type) => {
  return dispatch => {
    dispatch({
      type: UPDATING_USER
    });

    let formData = new FormData();
    if (userData.first_name) formData.append('first_name', userData.first_name);
    if (userData.last_name) formData.append('last_name', userData.last_name);
    if (userData.msisdn)
      formData.append('msisdn', userData.msisdn.slice(1).trim() || '');
    if (userData.email) formData.append('email', userData.email);
    if (userData.password) formData.append('password', userData.password);
    if (userData.preferences)
      formData.append('preferences', JSON.stringify(userData.preferences));
    if (file) formData.append('photo', file || null);

    return fetch(
      `${API_URL}/users/${JSON.parse(getCookie('user')).user_id}`, {
      headers: new Headers({
        Accept: 'application/json',
        // 'Content-Type': `multipart/form-data`,
        'x-access-token': `${getCookie('user_token')}`
      }),
      method: 'put',
      body: formData
    }
    ).then(response => {
      return response.json();
    }).then(json => {
      dispatch({ type: UPDATING_USER_FAILED });
      let user = json.result;
      if (json.statusCode === 200) {
        let yuser = (user);
        // let yuser = setLoginStorage(user);
        setCookie('user', JSON.stringify(yuser));
        dispatch({ type: UPDATING_USER_SUCCESS, user: yuser });
        switch (type) {
          case 'password':
            notification.success({
              message: intl.formatMessage(messages.passwordChanged)
            });
            break;
          case 'profile':
            notification.success({
              message: intl.formatMessage(messages.profileUpdated)
            });
            break;
          default:
            break;
        }
      } else {
        switch (type) {
          case 'password':
            notification.success({
              message: intl.formatMessage(messages.passwordFailed)
            });
            break;
          case 'profile':
            notification.error({
              message: intl.formatMessage(messages.profileUpdateFailed)
            });
            break;
          default:
            break;
        }
      }
    }).catch(err => {
      dispatch({ type: UPDATING_USER_FAILED });
      notification.error({
        message: intl.formatMessage(messages.networkError)
      });
    });
  };
};

// `${API_URL}/auth/reset/${token}`

export const updateUserReset = (intl, userData, resetToken) => {
  return dispatch => {
    dispatch({
      type: RESETTING_PASSWORD
    });
    return fetch(`${API_URL}/auth/reset/${resetToken}`, {
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }),
      method: 'post',
      body: JSON.stringify(userData)
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.statusCode === 200) {
          dispatch({
            type: RESETTING_PASSWORD_SUCCESS
          });
          notification.info({
            message: intl.formatMessage(messages.passwordChanged)
          });

          return json;
        } else {
          dispatch({ type: RESETTING_PASSWORD_FAILED });
          notification.error({
            message: intl.formatMessage(messages.resetEmailFailed)
          });
        }
      })
      .catch(err => {
        dispatch({ type: RESETTING_PASSWORD_FAILED });
        notification.error({
          message: intl.formatMessage(messages.networkError)
        });
      });
  };
};

export const createAccount = (intl, userData) => {
  return dispatch => {
    dispatch({
      type: CREATING_ACCOUNT
    });
    dispatch(
      sendMetric(
        'increment',
        `Web`,
        'This metric is to measure the total number of requests made for signup',
        {
          section: 'Signup Request',
          event: 'Total',
          version: `${REACT_APP_VERSION}`
        }
      )
    );

    return axios({
      method: 'post',
      url: `${API_URL}/auth/signup`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Basic ${base64.encode(
          userData.email + ':' + userData.password
        )}`
      },
      custMetric: {
        metricType: 'timing',
        metric: 'web_timer',
        help: 'This metric measures the duration of sign up requests',
        labels: {
          section: 'Signup Request',
          event: 'duration',
          version: `${REACT_APP_VERSION}`
        }
      },
      data: `type=web&firstName=${userData.firstName}&lastName=${userData.lastName
        }&msisdn=${userData.msisdn.slice(1).trim()}&country=${userData.country ? userData.country : ''
        }&collaboratedOrgId=${userData.collaboratedOrgId ? userData.collaboratedOrgId : ''
        }&language=${userData.language}&token=${userData.token ? userData.token : ''
        }&orgName=${userData.orgName || ''}&recaptcha=${userData.recaptcha
        }&package=${userData.package}&promoCode=${userData.promoCode ? userData.promoCode : ''
        }&industry=${userData.industry}`
    })
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: CREATING_ACCOUNT_SUCCESS,
            responseData: {
              token: response.data.token,
              user: response.data.user
            }
          });
          notification.success({
            message: intl.formatMessage(messages.signUpSuccess)
          });

          setCookie('user_token', response.data.token, 7);
          setLoginStorage(response.data.user);

          if (
            !!getCookie('appReferrerId') &&
            !!appTable[`${getCookie('appReferrerId')}`]
          ) {
            let tolkein = getCookie('appReferrerId');
            delCookie('appReferrerId');
            window.location.assign(`${appTable[`${tolkein}`]}/`);
          } else {
            dispatch(
              replace(`/${getCookie('site_lang') || 'en'}/forms`)
            );
          }

          dispatch({ type: 'SHOW_VERIFY_MODAL' });
        } else if (response.data.statusCode === 414) {
          dispatch({
            type: CREATING_ACCOUNT_FAILED,
            error_msg: response.data.message
          });
          notification.error({
            message: intl.formatMessage(messages.signUpCaptchaFailed)
          });
        } else if (
          response.data.statusCode >= 500 &&
          response.data.statusCode < 600
        ) {
          dispatch(
            sendMetric(
              'increment',
              `Web`,
              'This metric is to measure the number of failed requests made for signup',
              {
                section: 'Signup Request',
                event: 'Failed',
                version: `${REACT_APP_VERSION}`
              }
            )
          );
          dispatch({
            type: LOGGING_IN_FAILED
          });
        } else {
          dispatch({
            type: CREATING_ACCOUNT_FAILED,
            error_msg: response.data.message
          });
          notification.error({
            message: intl.formatMessage(messages.signUpFailed)
          });
        }
      })
      .catch(err => {
        //server error
        dispatch({
          type: CREATING_ACCOUNT_FAILED,
          error_msg: err
        });
        dispatch(
          sendMetric(
            'increment',
            `Web`,
            'This metric is to measure the number of unreachable server errors on signup',
            {
              section: 'Signup Request',
              event: 'Unreachable',
              version: `${REACT_APP_VERSION}`
            }
          )
        );
        notification.error({
          message: intl.formatMessage(messages.networkError)
        });
      });
  };
};

export const resetPasswordAction = (email, intl) => {
  return dispatch => {
    dispatch({ type: RESETTING_PASSWORD });
    dispatch(
      sendMetric(
        'increment',
        `Web`,
        'This metric is to measure the total number of requests made for password reset',
        {
          section: 'Reset Password Request',
          event: 'Total',
          version: `${REACT_APP_VERSION}`
        }
      )
    );

    return axios({
      method: 'post',
      url: `${API_URL}/auth/forgot`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      custMetric: {
        metricType: 'timing',
        metric: 'web_timer',
        help: 'This metric measures the duration of password reset requests',
        labels: {
          section: 'Reset Password Request',
          event: 'duration',
          version: `${REACT_APP_VERSION}`
        }
      },
      data: { email }
    })
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: RESETTING_PASSWORD_SUCCESS,
            reset_msg:
              'An email has been sent to ' +
              email +
              '. Click on the link in the email to reset your password'
          });
          notification.info({
            message: intl.formatMessage(messages.resetEmailSent)
          });

          setTimeout(() => {
            dispatch(
              replace(`/${getCookie('site_lang') || 'en'}/login`)
            );
          }, 4500);

          setTimeout(() => {
            dispatch({ type: RESETTING_PASSWORD_SUCCESS, hide: true });
          }, 5000);
        } else if (
          response.data.statusCode >= 500 &&
          response.data.statusCode < 600
        ) {
          dispatch(
            sendMetric(
              'increment',
              `Web`,
              'This metric is to measure the number of failed requests made for password reset',
              {
                section: 'Reset Password Request',
                event: 'Failed',
                version: `${REACT_APP_VERSION}`
              }
            )
          );
          dispatch({
            type: RESETTING_PASSWORD_FAILED,
            reset_msg: 'Unable to reset password. Please try again'
          });
        } else {
          dispatch({
            type: RESETTING_PASSWORD_FAILED,
            reset_msg: 'Unable to reset password. Please try again'
          });
        }
      })
      .catch(err => {
        dispatch({
          type: RESETTING_PASSWORD_FAILED,
          reset_msg: 'Unable to reset password. Please try again'
        });

        dispatch(
          sendMetric(
            'increment',
            `Web`,
            'This metric is to measure the number of unreachable server errors on password reset',
            {
              section: 'Reset Password Request',
              event: 'Unreachable',
              version: `${REACT_APP_VERSION}`
            }
          )
        );

        notification.error({
          message: intl.formatMessage(messages.resetEmailFailed)
        });
      });
  };
};

export const verifyResetToken = token => {
  return dispatch => {
    dispatch({
      type: VALIDATING_TOKEN
    });
    return fetch(`${API_URL}/auth/reset/${token}`, {
      headers: new Headers({
        Accept: 'application/json',
        'Content-type': 'application/x-www-form-urlencoded'
      }),
      method: 'get'
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.statusCode === 200) {
          dispatch({
            type: VALIDATING_TOKEN_SUCCESS,
            validUser: json.result
          });
        } else if (json.statusCode === 498) {
          dispatch({
            type: VALIDATING_TOKEN_FAILED
          });
        } else {
          dispatch({
            type: VALIDATING_TOKEN_FAILED
          });
        }
      })
      .catch(err => {
        dispatch({
          type: VALIDATING_TOKEN_FAILED
        });
      });
  };
};

export const updateProjectDetails = (intl, pData, projectId) => {
  return dispatch => {
    dispatch({ type: UPDATING_PROJECT_DETAILS });
    axios({
      method: 'put',
      url: `${API_URL}/projects/${projectId}`,
      headers: {
        Accept: 'application/json',
        'x-access-token': `${getCookie('user_token')}`
      },
      data: pData
    })
      .then(response => {
        if (response.data.statusCode === 200) {
          dispatch({
            type: UPDATING_PROJECT_DETAILS_SUCCESS,
            project: response.data.result
          });

          notification.success({
            message: intl.formatMessage(messages.profileUpdateSuccess)
          });
        } else {
          dispatch({ type: DELETING_SURVEY_RESPONSES_FAILED });
          notification.error({
            message: intl.formatMessage(messages.profileUpdateFailed)
          });
        }
      })
      .catch(err => {
        dispatch({ type: DELETING_SURVEY_RESPONSES_FAILED });
        notification.error({
          message: intl.formatMessage(messages.networkError)
        });
      });
  };
};
