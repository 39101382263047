export const CHANGE_LOCALE = 'CHANGE_LOCALE';

export const LOGGING_IN = 'LOGGING_IN';
export const LOGGING_IN_SUCCESS = 'LOGGING_IN_SUCCESS';
export const LOGGING_IN_FAILED = 'LOGGING_IN_FAILED';

export const FETCHING_USER = 'FETCHING_USER';
export const FETCHING_USER_SUCCESS = 'FETCHING_USER_SUCCESS';
export const FETCHING_USER_FAILED = 'FETCHING_USER_FAILED';

export const FETCHING_PROJECT = 'FETCHING_PROJECT';
export const FETCHING_PROJECT_SUCCESS = 'FETCHING_PROJECT_SUCCESS';
export const FETCHING_PROJECT_FAILED = 'FETCHING_PROJECT_FAILED';

export const FETCHING_COLLAB_PROJECTS = 'FETCHING_COLLAB_PROJECTS';
export const FETCHING_COLLAB_PROJECTS_SUCCESS = 'FETCHING_COLLAB_PROJECTS_SUCCESS';
export const FETCHING_COLLAB_PROJECTS_FAILED = 'FETCHING_COLLAB_PROJECTS_FAILED';

export const FETCHING_PROJECT_SURVEYS = 'FETCHING_PROJECT_SURVEYS';
export const FETCHING_PROJECT_SURVEYS_SUCCESS = 'FETCHING_PROJECT_SURVEYS_SUCCESS';
export const FETCHING_PROJECT_SURVEYS_FAILED = 'FETCHING_PROJECT_SURVEYS_FAILED';

export const TOGGLE_CREATE_SURVEY_MODAL = 'TOGGLE_CREATE_SURVEY_MODAL';
export const TOGGLE_ALTERNATE_SIDEBAR = 'TOGGLE_ALTERNATE_SIDEBAR';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

export const SET_ACTIVE_SURVEY = 'SET_ACTIVE_SURVEY';
export const UNSET_ACTIVE_SURVEY = 'UNSET_ACTIVE_SURVEY';

export const CREATING_SURVEY = 'CREATING_SURVEY';
export const CREATING_SURVEY_SUCCESS = 'CREATING_SURVEY_SUCCESS';
export const CREATING_SURVEY_FAILED = 'CREATING_SURVEY_FAILED';

export const CLONING_SURVEY = 'CLONING_SURVEY';
export const CLONING_SURVEY_SUCCESS = 'CLONING_SURVEY_SUCCESS';
export const CLONING_SURVEY_FAILED = 'CLONING_SURVEY_FAILED';

export const DELETING_SURVEY = 'DELETING_SURVEY';
export const DELETING_SURVEY_SUCCESS = 'DELETING_SURVEY_SUCCESS';
export const DELETING_SURVEY_FAILED = 'DELETING_SURVEY_FAILED';

export const UPDATING_USER = 'UPDATING_USER';
export const UPDATING_USER_SUCCESS = 'UPDATING_USER_SUCCESS';
export const UPDATING_USER_FAILED = 'UPDATING_USER_FAILED';

export const UPDATING_PASSWORD = 'UPDATING_PASSWORD';
export const UPDATING_PASSWORD_SUCCESS = 'UPDATING_PASSWORD_SUCCESS';
export const UPDATING_PASSWORD_FAILED = 'UPDATING_PASSWORD_FAILED';

export const LOGGING_OUT = 'LOGGING_OUT';
export const LOGGING_OUT_SUCCESS = 'LOGGING_OUT_SUCCESS';

export const FETCHING_SURVEY = 'FETCHING_SURVEY';
export const FETCHING_SURVEY_SAVING = 'FETCHING_SURVEY_SAVING';
export const FETCHING_SURVEY_SUCCESS = 'FETCHING_SURVEY_SUCCESS';
export const FETCHING_SURVEY_FAILED = 'FETCHING_SURVEY_FAILED';

export const FETCHING_SURVEY_RESPONSES = 'FETCHING_SURVEY_RESPONSES';
export const FETCHING_SURVEY_RESPONSES_SUCCESS =
  'FETCHING_SURVEY_RESPONSES_SUCCESS';
export const FETCHING_SURVEY_RESPONSES_FAILED =
  'FETCHING_SURVEY_RESPONSES_FAILED';

export const CREATING_ACCOUNT = 'CREATING_ACCOUNT';
export const CREATING_ACCOUNT_SUCCESS = 'CREATING_ACCOUNT_SUCCESS';
export const CREATING_ACCOUNT_FAILED = 'CREATING_ACCOUNT_FAILED';

export const RESETTING_PASSWORD = 'RESETTING_PASSWORD';
export const RESETTING_PASSWORD_SUCCESS = 'RESETTING_PASSWORD_SUCCESS';
export const RESETTING_PASSWORD_FAILED = 'RESETTING_PASSWORD_FAILED';

export const VALIDATING_TOKEN = 'VALIDATING_TOKEN';
export const VALIDATING_TOKEN_SUCCESS = 'VALIDATING_TOKEN_SUCCESS';
export const VALIDATING_TOKEN_FAILED = 'VALIDATING_TOKEN_FAILED';

export const DOWNLOADING_RESPONSES_IN_EXCEL = 'DOWNLOADING_RESPONSES_IN_EXCEL';
export const DOWNLOADING_RESPONSES_IN_EXCEL_SUCCESS =
  'DOWNLOADING_RESPONSES_IN_EXCEL_SUCCESS';
export const DOWNLOADING_RESPONSES_IN_EXCEL_FAILED =
  'DOWNLOADING_RESPONSES_IN_EXCEL_FAILED';

export const DOWNLOADING_RESPONSE_IMAGES = 'DOWNLOADING_RESPONSE_IMAGES';
export const DOWNLOADING_RESPONSE_IMAGES_SUCCESS =
  'DOWNLOADING_RESPONSE_IMAGES_SUCCESS';
export const DOWNLOADING_RESPONSE_IMAGES_FAILED =
  'DOWNLOADING_RESPONSE_IMAGES_FAILED';

export const DOWNLOADING_RESPONSE_SIGNATURES =
  'DOWNLOADING_RESPONSE_SIGNATURES';
export const DOWNLOADING_RESPONSE_SIGNATURES_SUCCESS =
  'DOWNLOADING_RESPONSE_SIGNATURES_SUCCESS';
export const DOWNLOADING_RESPONSE_SIGNATURES_FAILED =
  'DOWNLOADING_RESPONSE_SIGNATURES_FAILED';

export const DELETING_SURVEY_RESPONSES = 'DELETING_SURVEY_RESPONSES';
export const DELETING_SURVEY_RESPONSES_SUCCESS =
  'DELETING_SURVEY_RESPONSES_SUCCESS';
export const DELETING_SURVEY_RESPONSES_FAILED =
  'DELETING_SURVEY_RESPONSES_FAILED';

export const UPDATING_PROJECT_DETAILS = 'UPDATING_PROJECT_DETAILS';
export const UPDATING_PROJECT_DETAILS_SUCCESS =
  'UPDATING_PROJECT_DETAILS_SUCCESS';
export const UPDATING_PROJECT_DETAILS_FAILED =
  'UPDATING_PROJECT_DETAILS_FAILED';

export const ADD_ADMIN = 'ADD_ADMIN';
export const ADD_ADMIN_SUCCESS = 'ADD_ADMIN_SUCCESS';
export const ADD_ADMIN_FAILED = 'ADD_ADMIN_FAILED';

export const FETCHING_ADMINS = 'FETCHING_ADMINS';
export const FETCHING_ADMINS_SUCCESS = 'FETCHING_ADMINS_SUCCESS';
export const FETCHING_ADMINS_FAILED = 'FETCHING_ADMINS_FAILED';

export const INVITING_ADMIN = 'INVITING_ADMIN';
export const INVITING_ADMIN_SUCCESS = 'INVITING_ADMIN_SUCCESS';
export const INVITING_ADMIN_FAILED = 'INVITING_ADMIN_FAILED';

export const UPDATING_ADMIN_DETAILS = 'UPDATING_ADMIN_DETAILS';
export const UPDATING_ADMIN_DETAILS_SUCCESS = 'UPDATING_ADMIN_DETAILS_SUCCESS';
export const UPDATING_ADMIN_DETAILS_FAILED = 'UPDATING_ADMIN_DETAILS_FAILED';

export const VERIFYING_ADMIN = 'VERIFYING_ADMIN';
export const VERIFYING_ADMIN_SUCCESS = 'VERIFYING_ADMIN_SUCCESS';
export const VERIFYING_ADMIN_FAILED = 'VERIFYING_ADMIN_FAILED';

export const REMOVE_ADMIN = 'REMOVE_ADMIN';
export const REMOVE_ADMIN_SUCCESS = 'REMOVE_ADMIN_SUCCESS';
export const REMOVE_ADMIN_FAILED = 'REMOVE_ADMIN_FAILED';

export const FETCHING_FORM_REPORT = 'FETCHING_FORM_REPORT';
export const FETCHING_FORM_REPORT_SUCCESS = 'FETCHING_FORM_REPORT_SUCCESS';
export const FETCHING_FORM_REPORT_FAILED = 'FETCHING_FORM_REPORT_FAILED';

export const FETCHING_AGENTS_LOCATION = 'FETCHING_AGENTS_LOCATION';
export const FETCHING_AGENTS_LOCATION_SUCCESS =
  'FETCHING_AGENTS_LOCATION_SUCCESS';
export const FETCHING_AGENTS_LOCATION_FAILED =
  'FETCHING_AGENTS_LOCATION_FAILED';

export const FETCHING_RESPONSES_LOCATION = 'FETCHING_RESPONSES_LOCATION';
export const FETCHING_RESPONSES_LOCATION_SUCCESS =
  'FETCHING_RESPONSES_LOCATION_SUCCESS';
export const FETCHING_RESPONSES_LOCATION_FAILED =
  'FETCHING_RESPONSES_LOCATION_FAILED';

export const FETCHING_DUPLICATE_RESPONDENTS = 'FETCHING_DUPLICATE_RESPONDENTS';
export const FETCHING_DUPLICATE_RESPONDENTS_SUCCESS =
  'FETCHING_DUPLICATE_RESPONDENTS_SUCCESS';
export const FETCHING_DUPLICATE_RESPONDENTS_FAILED =
  'FETCHING_DUPLICATE_RESPONDENTS_FAILED';

export const FETCHING_CHART_DATA = 'FETCHING_CHART_DATA';
export const FETCHING_CHART_DATA_SUCCESS = 'FETCHING_CHART_DATA_SUCCESS';
export const FETCHING_CHART_DATA_FAILED = 'FETCHING_CHART_DATA_FAILED';

export const FETCHING_DESC_STATS = 'FETCHING_DESC_STATS';
export const FETCHING_DESC_STATS_SUCCESS = 'FETCHING_DESC_STATS_SUCCESS';
export const FETCHING_DESC_STATS_FAILED = 'FETCHING_DESC_STATS_FAILED';

export const UPLOADING_TREE_EXCEL_SHEET = 'UPLOADING_TREE_EXCEL_SHEET';
export const UPLOADING_TREE_EXCEL_SHEET_SUCCESS =
  'UPLOADING_TREE_EXCEL_SHEET_SUCCESS';
export const UPLOADING_TREE_EXCEL_SHEET_FAILED =
  'UPLOADING_TREE_EXCEL_SHEET_FAILED';

export const FETCHING_AGENTS_STATS = 'FETCHING_AGENTS_STATS';
export const FETCHING_AGENTS_STATS_SUCCESS = 'FETCHING_AGENTS_STATS_SUCCESS';
export const FETCHING_AGENTS_STATS_FAILED = 'FETCHING_AGENTS_STATS_FAILED';

export const POSTING_FORM_ERRORS = 'POSTING_FORM_ERRORS';
export const POSTING_FORM_ERRORS_SUCCESS = 'POSTING_FORM_ERRORS_SUCCESS';
export const POSTING_FORM_ERRORS_FAILED = 'POSTING_FORM_ERRORS_FAILED';

export const FETCHING_FORM_ERRORS = 'FETCHING_FORM_ERRORS';
export const FETCHING_FORM_ERRORS_SUCCESS = 'FETCHING_FORM_ERRORS_SUCCESS';
export const FETCHING_FORM_ERRORS_FAILED = 'FETCHING_FORM_ERRORS_FAILED';

export const SAVING_FAVOURITE_CHART = 'SAVING_FAVOURITE_CHART';
export const SAVING_FAVOURITE_CHART_SUCCESS = 'SAVING_FAVOURITE_CHART_SUCCESS';
export const SAVING_FAVOURITE_CHART_FAILED = 'SAVING_FAVOURITE_CHART_FAILED';

export const LOADING_FAVOURITE_CHART = 'LOADING_FAVOURITE_CHART';
export const LOADING_FAVOURITE_CHART_SUCCESS =
  'LOADING_FAVOURITE_CHART_SUCCESS';
export const LOADING_FAVOURITE_CHART_FAILED = 'LOADING_FAVOURITE_CHART_FAILED';

export const SETTING_RESPONSE_FLAG = 'SETTING_RESPONSE_FLAG';
export const SETTING_RESPONSE_FLAG_SUCCESS = 'SETTING_RESPONSE_FLAG_SUCCESS';
export const SETTING_RESPONSE_FLAG_FAILED = 'SETTING_RESPONSE_FLAG_FAILED';

export const FETCHING_RESPONSE_COMMENTS = 'FETCHING_RESPONSE_COMMENTS';
export const FETCHING_RESPONSE_COMMENTS_SUCCESS =
  'FETCHING_RESPONSE_COMMENTS_SUCCESS';
export const FETCHING_RESPONSE_COMMENTS_FAILED =
  'FETCHING_RESPONSE_COMMENTS_FAILED';

export const FETCHING_RESPONSE_AUDITS = 'FETCHING_RESPONSE_AUDITS';
export const FETCHING_RESPONSE_AUDITS_SUCCESS =
  'FETCHING_RESPONSE_AUDITS_SUCCESS';
export const FETCHING_RESPONSE_AUDITS_FAILED =
  'FETCHING_RESPONSE_AUDITS_FAILED';

export const FETCHING_SELECTED_FLAG_RESPONSES =
  'FETCHING_SELECTED_FLAG_RESPONSES';
export const FETCHING_SELECTED_FLAG_RESPONSES_SUCCESS =
  'FETCHING_SELECTED_FLAG_RESPONSES_SUCCESS';
export const FETCHING_SELECTED_FLAG_RESPONSES_FAILED =
  'FETCHING_SELECTED_FLAG_RESPONSES_FAILED';

export const FETCHING_QUESTIONS_AUDIT_TRAIL = 'FETCHING_QUESTIONS_AUDIT_TRAIL';
export const FETCHING_QUESTIONS_AUDIT_TRAIL_SUCCESS =
  'FETCHING_QUESTIONS_AUDIT_TRAIL_SUCCESS';
export const FETCHING_QUESTIONS_AUDIT_TRAIL_FAILED =
  'FETCHING_QUESTIONS_AUDIT_TRAIL_FAILED';

export const SEARCH_FOR_RESPONSE = 'SEARCH_FOR_RESPONSE';
export const SEARCH_FOR_RESPONSE_SUCCESS = 'SEARCH_FOR_RESPONSE_SUCCESS';
export const SEARCH_FOR_RESPONSE_FAILED = 'SEARCH_FOR_RESPONSE_FAILED';

export const REARRANGE_PAGES = 'REARRANGE_PAGES';

export const RESTRICT_AGENT_ON_FORM = 'RESTRICT_AGENT_ON_FORM';
export const RESTRICT_AGENT_ON_FORM_SUCCESS = 'RESTRICT_AGENT_ON_FORM_SUCCESS';
export const RESTRICT_AGENT_ON_FORM_FAILED = 'RESTRICT_AGENT_ON_FORM_FAILED';

export const RE_CALCULATE = 'RE_CALCULATE';
export const RE_CALCULATE_SUCCESS = 'RE_CALCULATE_SUCCESS';
export const RE_CALCULATE_FAILED = 'RE_CALCULATE_FAILED';

export const HIDE_MODAL = 'HIDE_MODAL';
export const SHOW_MODAL = 'SHOW_MODAL';
export const FILTER_AGENTS_STATS = 'FILTER_AGENTS_STATS';
export const FILTER_SURVEY_QUESTIONS = 'FILTER_SURVEY_QUESTIONS';

export const SAVE_EDITED_RESPONSE = 'SAVE_EDITED_RESPONSE';
export const SAVE_EDITED_RESPONSE_SUCCESS = 'SAVE_EDITED_RESPONSE_SUCCESS';
export const SAVE_EDITED_RESPONSE_FAILED = 'SAVE_EDITED_RESPONSE_FAILED';

export const FETCHING_FILTERED_SURVEY_RESPONSES =
  'FETCHING_FILTERED_SURVEY_RESPONSES';
export const FETCHING_FILTERED_SURVEY_RESPONSES_SUCCESS =
  'FETCHING_FILTERED_SURVEY_RESPONSES_SUCCESS';
export const FETCHING_FILTERED_SURVEY_RESPONSES_FAILED =
  'FETCHING_FILTERED_SURVEY_RESPONSES_FAILED';

export const ASSIGNING_SURVEY_RESPONSES_TO_AGENT =
  'ASSIGNING_SURVEY_RESPONSES_TO_AGENT';
export const ASSIGNING_SURVEY_RESPONSES_TO_AGENT_SUCCESS =
  'ASSIGNING_SURVEY_RESPONSES_TO_AGENT_SUCCESS';
export const ASSIGNING_SURVEY_RESPONSES_TO_AGENT_FAILED =
  'ASSIGNING_SURVEY_RESPONSES_TO_AGENT_FAILED';
export const SET_ADVANCED_SEARCH_VALUE = 'SET_ADVANCED_SEARCH_VALUE';
export const DELETE_ADVANCED_SEARCH_ITEM_VALUE =
  'DELETE_ADVANCED_SEARCH_ITEM_VALUE';
export const TOGGLE_CREATE_REVIEW_EXERCISE_MODAL =
  'TOGGLE_CREATE_REVIEW_EXERCISE_MODAL';

export const CREATE_REVIEW_EXERCISE = 'CREATE_REVIEW_EXERCISE';
export const CREATE_REVIEW_EXERCISE_SUCCESS = 'CREATE_REVIEW_EXERCISE_SUCCESS';
export const CREATE_REVIEW_EXERCISE_FAILED = 'CREATE_REVIEW_EXERCISE_FAILED';

export const FETCH_REVIEW_EXERCISE = 'FETCH_REVIEW_EXERCISE';
export const FETCH_REVIEW_EXERCISE_SUCCESS = 'FETCH_REVIEW_EXERCISE_SUCCESS';
export const FETCH_REVIEW_EXERCISE_FAILED = 'FETCH_REVIEW_EXERCISE_FAILED';
export const TOGGLE_DOWNLOAD_LATER_MODAL = 'TOGGLE_DOWNLOAD_LATER_MODAL';

export const UPDATE_REVIEW_EXERCISE = 'UPDATE_REVIEW_EXERCISE';
export const UPDATE_REVIEW_EXERCISE_SUCCESS = 'UPDATE_REVIEW_EXERCISE_SUCCESS';
export const UPDATE_REVIEW_EXERCISE_FAILED = 'UPDATE_REVIEW_EXERCISE_FAILED';

export const FETCH_INVITATIONS = 'FETCH_INVITATIONS';
export const FETCH_INVITATIONS_SUCCESS = 'FETCH_INVITATIONS_SUCCESS';
export const FETCH_INVITATIONS_FAILED = 'FETCH_INVITATIONS_FAILED';


export  const FETCH_FORM_TEMPLATES = 'FETCH_FORM_TEMPLATES' ;
export const  UPDATE_FORM_TEMPLATES= 'UPDATE_FORM_TEMPLATES';
export const  FETCH_FORM_TEMPLATES_SUCCESS = 'FETCH_FORM_TEMPLATES_SUCCESS' ;
export const  FETCH_FORM_TEMPLATES_ERROR =  'FETCH_FORM_TEMPLATES_ERROR' ;

export const DECLINE_INVITE = 'DECLINE_INVITE';
export const DECLINE_INVITE_SUCCESS = 'DECLINE_INVITE_SUCCESS';
export const DECLINE_INVITE_FAILED = 'DECLINE_INVITE_FAILED';

export const APPROVE_INVITE = 'APPROVE_INVITE';
export const APPROVE_INVITE_SUCCESS = 'APPROVE_INVITE_SUCCESS';
export const APPROVE_INVITE_FAILED = 'APPROVE_INVITE_FAILED';

export const TOGGLE_NOTIFICATIONS_MODAL = 'TOGGLE_NOTIFICATIONS_MODAL';

export const SEARCH_REVIEW_LIST = 'SEARCH_REVIEW_LIST';
export const SEARCH_REVIEW_LIST_SUCCESS = 'SEARCH_REVIEW_LIST_SUCCESS';
export const SEARCH_REVIEW_LIST_FAILED = 'SEARCH_REVIEW_LIST_FAILED';

export const ASSIGN_LIST_TO_AGENT = 'ASSIGN_LIST_TO_AGENT';
export const ASSIGN_LIST_TO_AGENT_SUCCESS = 'ASSIGN_LIST_TO_AGENT_SUCCESS';
export const ASSIGN_LIST_TO_AGENT_FAILED = 'ASSIGN_LIST_TO_AGENT_FAILED';

export const FETCH_PROJECT_AGENTS = 'FETCH_PROJECT_AGENTS';
export const FETCH_PROJECT_AGENTS_SUCCESS = 'FETCH_PROJECT_AGENTS_SUCCESS';
export const FETCH_PROJECT_AGENTS_FAILED = 'FETCH_PROJECT_AGENTS_FAILED';
export const SET_PAGE_SUB_TITLE = 'SET_PAGE_SUB_TITLE';

export const SAVING_BILLING_INTENT = 'SAVING_BILLING_INTENT';
export const SAVING_BILLING_INTENT_FAILED = 'SAVING_BILLING_INTENT_FAILED';
export const SAVING_BILLING_INTENT_SUCCESS = 'SAVING_BILLING_INTENT_SUCCESS';

export const POSTING_BILLING_INFO = 'POSTING_BILLING_INFO';
export const POSTING_BILLING_INFO_FAILED = 'POSTING_BILLING_INFO_FAILED';
export const POSTING_BILLING_INFO_SUCCESS = 'POSTING_BILLING_INFO_SUCCESS';

export const GET_BILLING_RECORDS = 'GET_BILLING_RECORDS';
export const GET_BILLING_RECORDS_FAILED = 'GET_BILLING_RECORDS_FAILED';
export const GET_BILLING_RECORDS_SUCCESS = 'GET_BILLING_RECORDS_SUCCESS';

export const GET_FORM_GEOFENCES = 'GET_FORM_GEOFENCES';
export const GET_FORM_GEOFENCES_FAILED = 'GET_FORM_GEOFENCES_FAILED';
export const GET_FORM_GEOFENCES_SUCCESS = 'GET_FORM_GEOFENCES_SUCCESS';

export const SAVE_FORM_GEOFENCE = 'SAVE_FORM_GEOFENCE';
export const SAVE_FORM_GEOFENCE_FAILED = 'SAVE_FORM_GEOFENCE_FAILED';
export const SAVE_FORM_GEOFENCE_SUCCESS = 'SAVE_FORM_GEOFENCE_SUCCESS';

export const UPDATE_FORM_GEOFENCE = 'UPDATE_FORM_GEOFENCE';
export const UPDATE_FORM_GEOFENCE_FAILED = 'UPDATE_FORM_GEOFENCE_FAILED';
export const UPDATE_FORM_GEOFENCE_SUCCESS = 'UPDATE_FORM_GEOFENCE_SUCCESS';

export const DELETE_FORM_GEOFENCE = 'DELETE_FORM_GEOFENCE';
export const DELETE_FORM_GEOFENCE_FAILED = 'DELETE_FORM_GEOFENCE_FAILED';
export const DELETE_FORM_GEOFENCE_SUCCESS = 'DELETE_FORM_GEOFENCE_SUCCESS';

export const ADDING_AGENTS_TO_GEOFENCE = 'ADDING_AGENTS_TO_GEOFENCE';
export const ADDING_AGENTS_TO_GEOFENCE_FAILED =
  'ADDING_AGENTS_TO_GEOFENCE_FAILED';
export const ADDING_AGENTS_TO_GEOFENCE_SUCCESS =
  'ADDING_AGENTS_TO_GEOFENCE_SUCCESS';
