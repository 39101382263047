import { REACT_APP_COOKIES_DOMAIN, STORAGE_PRFX } from './constants';
import dateFns from 'date-fns';

export const calculateNumberOfSurveyQuestions = questionaire => {
  return [].concat
    .apply(
      [],
      ((questionaire.draft_data || []).pages || []).map(page => page.questions)
    )
    .filter(
      question =>
        question.type !== 'section' &&
        question.type !== 'section-break' &&
        question.type !== 'intro-text'
    ).length;
};

export const setActiveSurvey = surveyId => {
  setCookie('survey_id', surveyId);
};

export const getActiveSurvey = () => {
  return getCookie('survey_id');
};

export const styledDate = (dateValue, intl) => {
  var oneDay = 24 * 60 * 60 * 1000;
  var currentdate = new Date();
  var days = Math.abs(currentdate - new Date(dateValue)) / oneDay;

  if (days <= 1) {
    return intl.formatDate(dateValue, {
      hour: 'numeric',
      minute: 'numeric'
    });
  } else if (days <= 7) {
    return intl.formatDate(dateValue, {
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric'
    });
  } else {
    return intl.formatDate(dateValue, {
      year: 'numeric',
      day: 'numeric',
      month: 'short'
    });
  }
};

// ::: cookie
export const setCookie = (cname, cvalue, exdays = 7) => {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = 'expires=' + d.toUTCString();
  // document.cookie = cname + '=' + cvalue + ';' + expires + ';domain=${REACT_APP_COOKIES_DOMAIN}';
  document.cookie = `${STORAGE_PRFX + cname}=${cvalue};${expires};path=/;domain=${REACT_APP_COOKIES_DOMAIN}`;
};

export const getCookie = cname => {
  var name = STORAGE_PRFX + cname + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      if (c.split('=')[1].length !== 0) {
        return c.substring(name.length, c.length);
      }
    }
  }
  return '';
};
export const delCookie = cname => {
  var expires = 'expires=-1';
  document.cookie = `${STORAGE_PRFX + cname}=;${expires};path=/;domain=${REACT_APP_COOKIES_DOMAIN}`;
  // cname + '=' + ';' + expires + ';path=/';
  // document.cookie=`${cname}=;expires=Wed; 01 Jan 1970`
};

// ::: storage
export const setStorage = (name, value) => {
  return localStorage.setItem(STORAGE_PRFX + name, value);
}
export const getStorage = (name, value) => {
  return localStorage.getItem(STORAGE_PRFX + name, value);
}
export const delStorage = (name) => {
  return localStorage.removeItem(STORAGE_PRFX + name);
}

export const rerouteUser = user => {
  const canAccessAbis = hasP('APP_ACCESS_ABIS', user.role);
  const canAccessAnalytics = hasP('ACCESS_ANALYTICS', user.role);

  if (canAccessAnalytics) {
    return window.location.assign(
      process.env.REACT_APP_ANALYTICS_APP_URL
        ? `${process.env.REACT_APP_ANALYTICS_APP_URL}/en/`
        : 'http://localhost:3001/en/'
    );
  }

  if (canAccessAbis) {
    return window.location.assign(
      process.env.REACT_APP_ABIS_APP_URL
        ? `${process.env.REACT_APP_ABIS_APP_URL}/en/`
        : 'http://localhost:3002/en/'
    );
  }
};

const randId = length => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const idGenerator = formId => {
  return dateFns.format(new Date(), 'YYMMDDHHssSSS') + randId(4) + '-' + formId;
};

// check permission
export const hasP = (permission, myRole = '') => {
  const thisRole = JSON.parse(getCookie('user') || '{}').role;
  if (thisRole) {
    var check = (process.env[`REACT_APP_${permission}`] || '').split(',').findIndex(role => role === thisRole);
    if (check === -1) {
      return false;
    }
    return true;
  }
  return false;
}


export function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function isEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export const imageExists = (img) => {
  var http = new XMLHttpRequest();
  http.open('HEAD', img, false);
  http.send();
  var status = http.status !== 404;
  return status ? img : require('../images/pngs/notfound.png');
}