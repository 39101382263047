import { LOGGING_OUT_SUCCESS } from '../../utils/types';
import {
  FETCH_REVIEW_EXERCISE_LIST_SUMMARY,
  FETCH_REVIEW_EXERCISE_LIST_SUMMARY_SUCCESS,
  FETCH_REVIEW_EXERCISE_LIST_SUMMARY_FAILED,
  FETCH_ACTIVE_REVIEW_EXERCISE,
  FETCH_ACTIVE_REVIEW_EXERCISE_SUCCESS,
  FETCH_ACTIVE_REVIEW_EXERCISE_FAILED,
  DELETE_REVIEW_EXERCISE,
  DELETE_REVIEW_EXERCISE_SUCCESS,
  DELETE_REVIEW_EXERCISE_FAILED,
  UPDATE_ACTIVE_REVIEW_EXERCISE,
  UPDATE_ACTIVE_REVIEW_EXERCISE_SUCCESS,
  UPDATE_ACTIVE_REVIEW_EXERCISE_FAILED,
  FETCH_REVIEW_EXERCISES_SUCCESS,
  FETCH_REVIEW_EXERCISES_FAILED,
  FETCH_REVIEW_EXERCISES,

  FETCH_RESTRICTIONS,
  FETCH_RESTRICTIONS_SUCCESS
} from './types';

const initialState = {
  fetching_active_review_exercise: false,
  updating_review_exercise: false,
  deleting_review_exercise: false,
  deleted_review: false,
  updated_review: false,
  fetching_active_review_exercise_summary: false,
  active_review_exercise: {},
  active_review_exercise_summary: {},
  fetching_review_exercises: false,
  review_exercises: [],

  restrictions: [],
  fetching_restrictions: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGGING_OUT_SUCCESS:
      return {
        fetching_active_review_exercise: false,
        fetching_active_review_exercise_summary: false,
        active_review_exercise: {},
        active_review_exercise_summary: {}
      };
    case FETCH_REVIEW_EXERCISES:
      return {
        ...state,
        fetching_review_exercises: true
      };

    case FETCH_REVIEW_EXERCISES_SUCCESS:
      return {
        ...state,
        fetching_review_exercises: false,
        review_exercises: action.payload['data']
      };

    case FETCH_REVIEW_EXERCISES_FAILED:
      return {
        ...state,
        fetching_review_exercises: false
      };
    case FETCH_ACTIVE_REVIEW_EXERCISE:
      return {
        ...state,
        fetching_active_review_exercise: true
      };

    case FETCH_ACTIVE_REVIEW_EXERCISE_SUCCESS:
      return {
        ...state,
        fetching_active_review_exercise: false,
        active_review_exercise: action.payload['data'][0]
      };

    case DELETE_REVIEW_EXERCISE_FAILED:
      return {
        ...state,
        deleting_review_exercise: false
      };
    case DELETE_REVIEW_EXERCISE:
      return {
        ...state,
        deleting_review_exercise: true
      };

    case DELETE_REVIEW_EXERCISE_SUCCESS:
      return {
        ...state,
        deleting_review_exercise: false,
        deleted_review: true
      };

    case UPDATE_ACTIVE_REVIEW_EXERCISE_FAILED:
      return {
        ...state,
        updating_review_exercise: false
      };
    case UPDATE_ACTIVE_REVIEW_EXERCISE:
      return {
        ...state,
        updating_review_exercise: true
      };

    case UPDATE_ACTIVE_REVIEW_EXERCISE_SUCCESS:
      return {
        ...state,
        updating_review_exercise: false,
        updated_review: true
      };

    case FETCH_ACTIVE_REVIEW_EXERCISE_FAILED:
      return {
        ...state,
        fetching_active_review_exercise: false
      };
    case FETCH_REVIEW_EXERCISE_LIST_SUMMARY:
      return {
        ...state,
        fetching_active_review_exercise_summary: true
      };

    case FETCH_REVIEW_EXERCISE_LIST_SUMMARY_SUCCESS:
      return {
        ...state,
        fetching_active_review_exercise_summary: false,
        active_review_exercise_summary: action.payload['data']
      };

    case FETCH_REVIEW_EXERCISE_LIST_SUMMARY_FAILED:
      return {
        ...state,
        fetching_active_review_exercise_summary: false
      };
    case FETCH_RESTRICTIONS:
      return {
        ...state,
        fetching_restrictions: action.status
      };
    case FETCH_RESTRICTIONS_SUCCESS:
      return {
        ...state,
        restrictions: action.data
      };
    default:
      return state;
  }
};
