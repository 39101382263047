import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import util from './util';
import intl from './intl';
import auth from './auth';
import dashboard from '../modules/Dashboard/reducer';
import settings from '../modules/Settings/reducer';
import surveys from '../modules/Surveys/reducer';
import review_exercises from '../modules/ReviewExercises/reducer';

export default combineReducers({
  router: routerReducer,
  dashboard,
  settings,
  surveys,
  intl,
  auth,
  review_exercises,
  util
});
