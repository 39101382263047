import axios from 'axios';
import { METRICS_ENDPOINT } from '../utils/constants';

export const sendMetric = (metricType, metric, help, labels, value) => {
  return dispatch => {
    dispatch({
      type: 'INSYT_METRICS_STARTED'
    });
    return axios({
      method: 'post',
      url: `${METRICS_ENDPOINT}`,
      data: {
        metricType,
        metric,
        help,
        labels,
        value
      }
    })
      .then(response => {
        if (response.data.statusCode >= 200 || response.data.statusCode < 300) {
          dispatch({ type: 'INSYT_METRICS_SENT' });
        } else {
          dispatch({ type: 'INSYT_METRICS_FAILED' });
        }
      })
      .catch(error => {
        dispatch({ type: 'INSYT_METRICS_FAILED' });
      });
  };
};

export const sendMetricNoDispatch = (
  metricType,
  metric,
  help,
  labels,
  value
) => {
  return axios({
    method: 'post',
    url: `${METRICS_ENDPOINT}`,
    data: {
      metricType,
      metric,
      help,
      labels,
      value
    }
  })
    .then(response => {
      if (response.data.statusCode >= 200 || response.data.statusCode < 300) {
      } else {
      }
    })
    .catch(error => {});
};
