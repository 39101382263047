import React from 'react';

export const Loader2 = props => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh'
      }}
    >
      <div className="insyt-loader" />
    </div>
  );
};
