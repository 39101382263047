import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button, Modal, Icon } from 'antd';

import { billingIntent } from '../modules/Dashboard/actions';
import { hideModal, showModal } from '../actions/utils';

const PaymentInfo = props => {
  return (
    <Modal
      centered
      className="billingInfoModal"
      destroyOnClose
      visible={props.modalShow}
      onCancel={() => props.hideModal()}
      footer={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <span style={{ fontSize: '.75rem' }}>
            <FormattedMessage
              defaultMessage="A one-time fee of $0.10 will be charged for card verification"
              id="App.Billing.VerificationFeeInfo"
            />
          </span>
          <Button
            type="primary"
            loading={props.billingLoading}
            onClick={() =>
              props.billingIntent(
                props.projectUuid,
                props.userUuid,
                props.currentUrl
              )
            }
          >
            {' '}
            <FormattedMessage defaultMessage="Proceed" id="App.Text.Proceed" />
          </Button>
        </div>
      }
    >
      <div className="billingInfoModal__Body">
        <div className="billingInfoModal__BodyLeft">
          <div>
            <div>
              <p style={{ fontSize: '19px' }}>
                <FormattedMessage
                  defaultMessage="Add a payment method"
                  id="billingInfoModal__BodyLeftHeader"
                />
              </p>
              <p style={{ marginTop: '.5rem' }}>
                <Icon type="credit-card" />{' '}
                <FormattedMessage
                  defaultMessage="Add credit or debit card"
                  id="billingInfoModal__BodyLeftSubHeader"
                />
              </p>
            </div>
            <div style={{ marginTop: '1.8rem' }}>
              <p style={{ fontSize: '1.2rem' }}>Pay only</p>
              <p className="price">$0.02</p>
              <span
                style={{
                  color: '#78BCFF',
                  fontSize: '15px'
                }}
              >
                <FormattedMessage
                  defaultMessage="Per form response per month"
                  id="billingInfoModal__BodyLeftSpan"
                />
              </span>
            </div>
          </div>
        </div>

        <div className="billingInfoModal__BodyRight">
          <p className="billingInfoModal__BodyRightHeader">
            <FormattedMessage
              defaultMessage="An upgrade gives you access to;"
              id="billingInfoModal__BodyRightHeader"
            />
          </p>
          <div className="billingInfoModal__BodyRightList">
            <p>
              <Icon
                type="check"
                style={{
                  color: '#0eb023',
                  fontSize: '21px',
                  marginRight: '.8rem'
                }}
              />{' '}
              <FormattedMessage
                defaultMessage="Unlimited forms"
                id="billingInfoStep1"
              />
            </p>
            <p>
              <Icon
                type="check"
                style={{
                  color: '#0eb023',
                  fontSize: '21px',
                  marginRight: '.8rem'
                }}
              />{' '}
              <FormattedMessage
                defaultMessage="Unlimited form responses"
                id="billingInfoStep2"
              />
            </p>
            <p>
              <Icon
                type="check"
                style={{
                  color: '#0eb023',
                  fontSize: '21px',
                  marginRight: '.8rem'
                }}
              />
              <FormattedMessage
                defaultMessage="Unlimited web and mobile users"
                id="billingInfoStep3"
              />
            </p>
            {/* <p>
            <Icon
              type="check"
              style={{
                color: '#0eb023',
                fontSize: '21px',
                marginRight: '.8rem'
              }}
            />
            <FormattedMessage
              defaultMessage="No contracts required"
              id="billingInfoStep4"
            />
          </p> */}
            <p>
              <Icon
                type="check"
                style={{
                  color: '#0eb023',
                  fontSize: '21px',
                  marginRight: '.8rem'
                }}
              />
              <FormattedMessage
                defaultMessage="Access to all advanced features including biometric data capture, signature & GIS"
                id="billingInfoStep5"
              />
            </p>
            <p>
              <Icon
                type="check"
                style={{
                  color: '#0eb023',
                  fontSize: '21px',
                  marginRight: '.8rem'
                }}
              />
              <FormattedMessage
                defaultMessage="Billed monthly"
                id="billingInfoModalStep6"
              />
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapState = state => {
  return {
    billingLoading: state.dashboard.billing_intent
  };
};

const mapDispatch = dispatch => {
  return {
    billingIntent: (project_uuid, user_uuid, currentUrl) => {
      dispatch(billingIntent(project_uuid, user_uuid, currentUrl));
    },
    hideModal: () => {
      dispatch(hideModal());
    },
    showModal: (modalName, modalProps) => {
      dispatch(showModal(modalName, modalProps));
    }
  };
};

export default connect(
  mapState,
  mapDispatch
)(PaymentInfo);
