import React from 'react';
import ReactDOM from 'react-dom';
import 'babel-polyfill';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';

import bugsnag from 'bugsnag-js';
import createPlugin from 'bugsnag-react';
import areIntlLocalesSupported from 'intl-locales-supported';
// import logger from 'redux-logger';
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';
import { REACT_APP_VERSION, RELEASE_STAGE } from './utils/constants';

import thunk from 'redux-thunk';
import rootReducer from './reducers';
import createHistory from 'history/createBrowserHistory';

// import { BrowserRouter as Router } from 'react-router-dom';
import App from './layouts/App';

import './styles/main.scss';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import axiosTiming from './utils/axios-timing';
import { getCookie, setCookie } from './utils/helpers';

axiosTiming(axios, console.log);

function IsStringJSON(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

setCookie('InsytAppVersion', REACT_APP_VERSION);

const appUser = IsStringJSON(getCookie('user'))
  ? JSON.parse(getCookie('user')) || {}
  : {};

let bugsnagClient;
if (appUser) {
  bugsnagClient = bugsnag({
    releaseStage: RELEASE_STAGE,
    notifyReleaseStages: ['production', 'staging'],
    apiKey: 'b75b4f8ca553b6b3536e61c82f737d49',
    appVersion: `${REACT_APP_VERSION}`,
    autoCaptureSessions: true,
    autoNotify: false,
    autoNotifyUnhandledRejection: false,
    beforeSend: function(report) {
      report.metaData = {
        user: {
          id: appUser.id,
          email: appUser.email,
          project_id: appUser.project_id,
          msisdn: appUser.msisdn,
          full_name: `${appUser.first_name} ${appUser.last_name}`,
          user_token: getCookie('user_token'),
          appVersion: `${REACT_APP_VERSION}`
        }
      };
    }
  });
} else {
  bugsnagClient = bugsnag({
    apiKey: 'b75b4f8ca553b6b3536e61c82f737d49',
    releaseStage: RELEASE_STAGE,
    notifyReleaseStages: ['production', 'staging'],
    appVersion: `${REACT_APP_VERSION}`,
    autoCaptureSessions: true,
    autoNotify: false,
    autoNotifyUnhandledRejection: false,
    beforeSend: function(report) {
      report.metaData = {
        user: {
          user_token: getCookie('user_token') || '',
          appVersion: `${REACT_APP_VERSION}`
        }
      };
    }
  });
}

const ErrorBoundary = bugsnagClient.use(createPlugin(React));
const history = createHistory();
const trackPageView = location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
};

const initGa = history => {
  ReactGA.initialize('UA-118971978-1', {
    debug: true
  });
  trackPageView(history.location);
  history.listen(trackPageView);
};

// if (whichMode === 'staging') {
//   hotjar.initialize(847511, 6);
// }

if (RELEASE_STAGE === 'production') {
  initGa(history);
  hotjar.initialize(847511, 6);
}
const composeEnhancers =
  // window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  //   ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
  //       trace: true
  //     })
  //   :
  compose;

const envMiddlewares =
  process.env.NODE_ENV === 'development' ? [thunk] : [thunk];

const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(routerMiddleware(history), ...envMiddlewares)
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

const FallbackComponent = ({ error, info }) => (
  <div>An error has occurred </div>
);

if (global.Intl) {
  // Determine if the built-in `Intl` has the locale data we need.

  if (!areIntlLocalesSupported(['en', 'fr', 'sw'])) {
    // `Intl` exists, but it doesn't have the data we need, so load the
    // polyfill and patch the constructors we need with the polyfill's.
    var IntlPolyfill = require('intl');
    Intl.NumberFormat = IntlPolyfill.NumberFormat;
    Intl.DateTimeFormat = IntlPolyfill.DateTimeFormat;
  }
} else {
  // No `Intl`, so use and load the polyfill.
  global.Intl = require('intl');
}

// Render our root component into the div with id "root"
// We select the messages to pass to IntlProvider based on the user's locale

ReactDOM.render(
  <ErrorBoundary FallbackComponent={FallbackComponent}>
    <CookiesProvider>
      <Provider store={store}>
        <App history={history} />
      </Provider>
    </CookiesProvider>
  </ErrorBoundary>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
