import React from 'react';
import axios from 'axios';

import { Modal, Button } from 'antd';
import { connect } from 'react-redux';
import { hideModal, showModal } from '../actions/utils';
import { FormattedDate, FormattedTime, FormattedMessage } from 'react-intl';
// import { subMonths } from 'date-fns';
import { API_URL } from '../utils/constants';
import { getCookie } from '../utils/helpers';

const htmlToPDF = fileName => {
  const input = document.querySelector('#insytReceipt');

  const createdHtml = `<html>
  <head>
  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">


  <style>
  body {
    zoom: 50%;
    padding: 2rem;
  }
  
    .receiptFormModal__logo {
      margin-top: 2rem;
      text-align: left;
    }

    
    img {
      width: 68px;
    }
  
    .receiptFormModal__Header {
      font-size: 1.3rem;
      font-weight: bold;
      color: black;
      margin: 1rem 0;
      text-align: right;
    }
  </style>
  </head>
  <body>
  ${input.outerHTML}
  </body>
  </html>`;
  return axios({
    method: 'post',
    url: `${API_URL}/utils/pdfdownload`,
    headers: {
      Accept: 'application/pdf',
      'x-access-token': `${getCookie('user_token')}`
    },
    data: {
      html: createdHtml,
      fileName
    }
  })
    .then(response => {
      if (response.data.statusCode === 200) {
        return axios({
          method: 'get',
          responseType: 'blob', // important
          url: `${API_URL}/utils/pdfdownload?fileName=${response.data.fileName}`
        })
          .then(response => {
            const blob = new Blob([response.data]);
            var fileDownload = require('react-file-download');
            window.open(
              fileDownload(blob, `${fileName} Receipt.pdf`),
              '_blank'
            );
          })
          .catch(error => {
            console.log(error);
          });
      }
    })
    .catch(error => {
      console.log(error);
    });
};

const PaymentReceipt = props => {

  return (
    <Modal
      destroyOnClose
      centered
      visible={props.modalShow}
      maskStyle={{
        background: 'rgba(0, 0, 0, 0.90)'
      }}
      className="receiptFormModal"
      onCancel={props.hideModal}
      footer={[
        <span
          key="back"
          style={{ color: '#0628A5', cursor: 'pointer', marginRight: '.8rem' }}
          onClick={props.hideModal}
        >
          <FormattedMessage defaultMessage="Close" id="Button.Close" />
        </span>,
        <Button
          key="download"
          type="primary"
          onClick={() => htmlToPDF('FileName')}
          data-insyt-id="downloadReceipt-Btn"
        >
          <FormattedMessage defaultMessage="Download" id="App.Text.Download" />
        </Button>
      ]}
    >
      <div id="insytReceipt">
        <div className="receiptFormModal__logo">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 98.153 39.872"
            width="10%"
            height="10%"
            style={{
              color: '#0628A5',
              fill: '#0628A5',
              enableBackground: 'new 0 0 1000 1000'
            }}
          >
            <g transform="translate(-64.847 -40)">
              <ellipse
                className="a"
                cx="3.864"
                cy="3.811"
                rx="3.864"
                ry="3.811"
                transform="translate(64.847 40)"
              />
              <path
                className="b"
                d="M51.9,850.2c3.671,0,6.376-.047,6.376,3.24v20.009h-3c-3.14,0-3.429-1.953-3.381-3.049Z"
                transform="translate(13.773 -801.482)"
              />
              <path
                className="b"
                d="M87.861,868.222c-.048,2.811.773,4.717,2.56,4.668h3.816V858.934a9.307,9.307,0,0,0-.145-2c-.531-3.24-2.077-5.859-5.313-6.955a12.4,12.4,0,0,0-12.465,2.665c0-3.24-1.546-2.906-5.214-2.906v20.2c0,1.1.242,3.049,3.381,3.049h3v-15.2c0-.524-.1-3.1,1.353-3.1a43.44,43.44,0,0,1,6.569.1c1.449.239,2.367,1.667,2.415,3.478C88.006,861.457,87.861,864.934,87.861,868.222Z"
                transform="translate(3.842 -801.019)"
              />
              <path
                className="b"
                d="M223.8,838h6.424v5.717h6.328v2.477c0,2.62-1.981,2.811-3.091,2.811H230.22v11.579a1.515,1.515,0,0,0,1.643,1.334l2.077.048a2.26,2.26,0,0,1,2.367,1.81c.145,1.286.145,2.239.193,3.382-3.188.1-7.055.191-9.854-1.524a5.766,5.766,0,0,1-2.8-5V848.957H220.8l.1-4.141c1.353.047,2.947.333,2.947-1.1V838Z"
                transform="translate(-73.547 -795.094)"
              />
              <path
                className="b"
                d="M175.974,854.764c.048-2.81-.773-4.717-2.56-4.668H169.6v13.956a9.311,9.311,0,0,0,.145,2c.531,3.24,2.077,5.859,5.313,6.955,4.3,1.429,8.742,1.191,11.5-2.477l-.1,3.859c-.048,1.238-.725,1.572-1.546,1.524h-9.322c-3.284,0-3.284,2.286-3.284,5.336l9.178-.1a13.4,13.4,0,0,0,8.695-2.668h0a7.5,7.5,0,0,0,2.607-5.291V853.049c0-1.1-.383-3.049-3.527-3.049h-2.9v15.483c0,.524,0,3.1-1.449,3.1a33,33,0,0,1-5.751.047.3.3,0,0,1-.193-.047,3.92,3.92,0,0,1-3.043-3.573C175.829,861.863,175.974,858,175.974,854.764Z"
                transform="translate(-47.077 -801.377)"
              />
              <path
                className="b"
                d="M130.124,857.722c.048-1.239-.193-2.62.58-2.62,3.091.047,6.135,0,9.226,0,3.284,0,3.284-1.906,3.284-5l-9.177.1a13.4,13.4,0,0,0-8.695,2.668h0c-1.3.9-2.367,5.291-.676,7.385,1.353,1.906,4.347,2.715,6.135,2.859.145,0,4.879.762,6.038,1.477,1.4.857,1.208,1.191,1.3,2.334a1.313,1.313,0,0,1-1.546,1.38h-9.321c-3.284,0-3.284,1.906-3.284,5l9.177-.1c3.719,0,6.763-.525,9.033-2.287a5.333,5.333,0,0,0,1.932-2.906,7.45,7.45,0,0,0-.048-3.716c-.383-1.333-1.739-2.525-3.671-3.335C138.336,860.1,130.269,859.39,130.124,857.722Z"
                transform="translate(-23.398 -801.43)"
              />
            </g>
          </svg>
        </div>
        <p className="receiptFormModal__Header">
          <FormattedMessage defaultMessage="Receipt" id="App.Text.Receipt" />
        </p>

        <table width="600" style={{ marginBottom: '1.8rem' }}>
          <tbody>
            <tr>
              <td><FormattedMessage id="App.Text.Receipt" defaultMessage="Receipt" /> #: {props.receipt.receipt_number}</td>
              <td><FormattedMessage id="App.Text.BillTo" defaultMessage="Bill to" />:</td>
            </tr>
            <tr>
              <td>
                <FormattedMessage id="App.Text.ReceiptDate" defaultMessage="Receipt Date" />:{' '}
                <FormattedDate value={props.receipt.issue_date} year="numeric" month="long" day="2-digit" />{' '}
                <FormattedTime value={props.receipt.issue_date} />
              </td>
              <td>
                <p style={{ margin: '0' }}>{props.receipt.owner} </p>
                <p style={{ margin: '0' }}>{props.receipt.email}</p>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                {/* <p style={{ margin: '0' }}>{props.receipt.owner} </p>
                <p style={{ margin: '0' }}>{props.receipt.email}</p> */}
              </td>
            </tr>
            <tr>
              {/* <td> Payment method: {props.package}</td> */}
              <td></td>
              <td>
                <p style={{ margin: '0' }}>{props.msisdn && props.msisdn}</p>
              </td>
            </tr>
          </tbody>
        </table>

        <table className="table">
          <thead style={{ backgroundColor: ' #DADFF2' }}>
            <tr>
              {/* <th scope="col" style={{ textAlign: 'left' }}>
                {' '}
                <FormattedMessage
                  defaultMessage="Subscription"
                  id="App.Text.Subscription"
                />
              </th> */}
              <th scope="col" style={{ textAlign: 'left' }}>
                {' '}
                <FormattedMessage
                  defaultMessage="Description"
                  id="App.Text.TimePeriod"
                />
              </th>
              <th scope="col" style={{ textAlign: 'right' }}>
                <FormattedMessage
                  defaultMessage="Amount"
                  id="App.Text.AmountUSD"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {/* <td>Insyt</td> */}
              <td>
                <p style={{ fontWeight: '500', color: 'black' }}>
                  {props.description}
                </p>
              </td>
              <td align="right"> {props.currency} {props.amount}</td>
            </tr>
            <tr>
              {/* <td style={{ borderBottom: '1px solid #dee2e6' }} /> */}
              <td style={{ fontWeight: 'bold', borderBottom: '1px solid #dee2e6' }} align="right">
                <FormattedMessage id="App.Text.Total" defaultMessage="Total" />:
              </td>
              <td style={{ fontWeight: 'bold', borderBottom: '1px solid #dee2e6' }} align="right">
                {props.currency} {props.amount}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    hideModal: () => {
      dispatch(hideModal());
    },
    showModal: (modalName, modalProps) => {
      dispatch(showModal(modalName, modalProps));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(PaymentReceipt);
