import { defineMessages } from 'react-intl';

export default defineMessages({
  noSuchResponse: {
    id: 'Notification.noSuchResponse',
    defaultMessage: 'No response(s) found'
  },
  textPlaceHolder: {
    id: 'textPlaceHolder',
    defaultMessage: 'Type a value here'
  },
  treeTextareaPlaceHolder: {
    id: 'treeTextareaPlaceHolder',
    defaultMessage: 'Provide list '
  },
  clearAllOptionsModal: {
    id: 'clearAllOptionsModal',
    defaultMessage: 'Are you sure you want to clear all options?'
  },
  deleteReviewExerciseSuccess: {
    id: 'Notification.deleteReviewExerciseSuccess',
    defaultMessage: 'Todo deleted sucessfully'
  },

  noSuchList: {
    id: 'Notification.noSuchList',
    defaultMessage: 'No lists found'
  },
  responseAssignmentToAgentSuccess: {
    id: 'Notification.responseAssignmentToAgentSuccess',
    defaultMessage: 'lists assigned to code successfully'
  },
  responseAssignmentToAgentFailed: {
    id: 'Notification.responseAssignmentToAgentFailed',
    defaultMessage: 'lists assigned to code failed'
  },
  listsAssignmentToAgentSuccess: {
    id: 'Notification.listsAssignmentToAgentSuccess',
    defaultMessage: 'lists assigned to code successfully'
  },
  listsAssignmentToAgentFailed: {
    id: 'Notification.listsAssignmentToAgentFailed',
    defaultMessage: 'lists assigned to code failed'
  },
  performanceovertimeText: {
    id: 'App.Text.performanceovertimeText',
    defaultMessage: 'performance over time'
  },
  performanceof: {
    id: 'App.Text.performanceof',
    defaultMessage: 'Performance of'
  },
  dateCollected: {
    id: 'App.Text.dateCollected',
    defaultMessage: 'Date Collected'
  },
  responseCount: {
    id: 'App.Text.responseCount',
    defaultMessage: 'Response count'
  },
  responseUpdateSuccess: {
    id: 'Notification.responseUpdateSuccess',
    defaultMessage: 'Response updated successfully'
  },
  responseUpdateFailure: {
    id: 'Notification.responseUpdateFailure',
    defaultMessage: 'Response update failed'
  },
  formPreferencesUpdateSuccess: {
    id: 'Notification.formPreferencesUpdateSuccess',
    defaultMessage: 'Form preferences updated successfully'
  },
  formPreferencesUpdateFailed: {
    id: 'Notification.formPreferencesUpdateFailed',
    defaultMessage: 'Form preferences update failed'
  },
  'App.Text.Geofences': {
    id: 'App.Text.Geofences',
    defaultMessage: 'Geofences'
  },
  'App.Text.Maps': {
    id: 'App.Text.Maps',
    defaultMessage: 'Maps'
  },

  'App.Text.Billing': {
    id: 'App.Text.Billing',
    defaultMessage: 'Billing'
  },
  'App.Text.Responses': {
    id: 'App.Text.Responses',
    defaultMessage: 'Responses'
  },
  'App.Text.Reports': {
    id: 'App.Text.Reports',
    defaultMessage: 'Reports'
  },
  'App.Text.Analytics': {
    id: 'App.Text.Analytics',
    defaultMessage: 'Analytics'
  },
  'App.Text.Todo': {
    id: 'App.Text.Todo',
    defaultMessage: 'Todo'
  },
  'App.Text.Settings': {
    id: 'App.Text.Settings',
    defaultMessage: 'Settings'
  },
  'App.Text.TodoLists': {
    id: 'App.Text.TodoLists',
    defaultMessage: 'TodoLists'
  },

  removedFavChart: {
    id: 'Notification.removedFavChart',
    defaultMessage: 'Chart removed from Dashboard'
  },
  addedFavChart: {
    id: 'Notification.addedFavChart',
    defaultMessage: 'Chart added to Dashboard'
  },
  save: {
    id: 'App.Text.Save',
    defaultMessage: 'Save'
  },
  cancel: {
    id: 'App.Text.Cancel',
    defaultMessage: 'Cancel'
  },
  Calculation: {
    id: 'App.Text.Calculation',
    defaultMessage: 'Calculation'
  },
  Video: {
    id: 'App.Text.Video',
    defaultMessage: 'Video'
  },
  Audio: {
    id: 'App.Text.Audio',
    defaultMessage: 'Audio'
  },
  QRCode: {
    id: 'App.Text.QRCode',
    defaultMessage: 'QR Code'
  },
  BarCode: {
    id: 'App.Text.BarCode',
    defaultMessage: 'Bar Code'
  },
  Email: {
    id: 'App.Text.Email',
    defaultMessage: 'Email address'
  },
  Phone: {
    id: 'App.Text.Phone',
    defaultMessage: 'Phone number'
  },
  deleteSurvey: {
    id: 'Notification.FormDeleted',
    defaultMessage: 'Form deleted successfully'
  },

  errorPushSuccess: {
    id: 'Notification.errorPushSuccess',
    defaultMessage: 'Response successfully returned to agent'
  },
  agentRestrictStatusActive: {
    id: 'Notification.agentRestrictStatusActive',
    defaultMessage: 'Agent status set to active'
  },
  agentRestrictStatusDenied: {
    id: 'Notification.agentRestrictStatusDenied',
    defaultMessage: 'Agent status set to restricted'
  },
  agentRestrictStatusFailed: {
    id: 'Notification.agentRestrictStatusFailed',
    defaultMessage: 'Agent status change failed'
  },
  noImagesFound: {
    id: 'Notification.noImagesFound',
    defaultMessage: 'No images available for this form'
  },
  noSignaturesFound: {
    id: 'Notification.noSignaturesFound',
    defaultMessage: 'No signatures available for this form'
  },
  errorPushFail: {
    id: 'Notification.errorPushFail',
    defaultMessage: 'Error push to agent failed'
  },
  errorWithAction: {
    id: 'Notification.errorWithAction',
    defaultMessage: 'An error occured whilst performing this action. Try Again'
  },
  networkError: {
    id: 'Notification.networkError',
    defaultMessage: 'No internet connection'
  },
  sessionExpired: {
    id: 'Notification.sessionExpired',
    defaultMessage: 'Session has expired'
  },
  updateFormDetails: {
    defaultMessage: 'Form details changed successfully',
    id: 'Notification.FormDetailsUpdated'
  },
  autoPublishOn: {
    defaultMessage: 'Auto publish turned on',
    id: 'Notification.AutoPublishOn'
  },
  autoPublishOff: {
    defaultMessage: 'Auto publish turned off',
    id: 'Notification.AutoPublishOff'
  },
  formPublished: {
    defaultMessage: 'Form published successfully',
    id: 'Notification.FormPublished'
  },
  formSaved: {
    defaultMessage: 'Form saved successfully',
    id: 'Notification.FormSaved'
  },
  formUnpublished: {
    defaultMessage: 'Form unpublished successfully',
    id: 'Notification.FormUnpublished'
  },
  verificationAccSuccess: {
    defaultMessage: 'Account verification successful',
    id: 'Notification.verificationAccSuccess'
  },
  verificationAccFailed: {
    defaultMessage:
      'Account verification failed or link has expired. Login to resend a verification mail.',
    id: 'Notification.verificationAccFailed'
  },
  resendEmailFailed: {
    defaultMessage: 'Resending email failed. Try again',
    id: 'Notification.resendEmailFailed'
  },
  resendEmailSuccess: {
    defaultMessage: 'Email resend successful',
    id: 'Notification.resendEmailSuccess'
  },
  cloningSuccess: {
    defaultMessage: 'Form duplicated successfully',
    id: 'Notification.cloningSuccess'
  },
  cloningFailed: {
    defaultMessage: 'Form duplication failed',
    id: 'Notification.cloningFailed'
  },
  inviteAlreadySent: {
    defaultMessage: 'An invitation has already been sent to this email',
    id: 'Notification.inviteAlreadySent'
  },
  collaboratorExists: {
    defaultMessage: 'This collaborator already exists in project',
    id: 'Notification.collaboratorExists'
  },
  collaboratorAdded: {
    defaultMessage: 'Collaborator added successfully',
    id: 'Notification.collaboratorAdded'
  },
  collaboratorAccessDenied: {
    defaultMessage: 'Collaborator does not have access to this project',
    id: 'Notification.collaboratorAccessDenied'
  },
  resetEmailSent: {
    id: 'Notification.resetEmailSent',
    defaultMessage: 'An email has been sent to your address'
  },
  resetEmailFailed: {
    id: 'Notification.resetEmailFailed',
    defaultMessage: 'Unable to reset password. Please try again'
  },

  passwordChanged: {
    id: 'Notification.passwordChanged',
    defaultMessage: 'Password changed successfully'
  },
  passwordFailed: {
    id: 'Notification.passwordFailed',
    defaultMessage: 'Password failed changing'
  },
  profileUpdated: {
    id: 'Notification.profileUpdated',
    defaultMessage: 'Profile details changed successfully'
  },
  preferencesUpdated: {
    id: 'Notification.preferencesUpdated',
    defaultMessage: 'Profile preferences changed successfully'
  },
  invalidCred: {
    id: 'Notification.invalidCredentials',
    defaultMessage: 'Invalid user credentials'
  },

  profileUpdateSuccess: {
    id: 'Notification.profileUpdateSuccess',
    defaultMessage: 'Organisation details changed successfully'
  },
  profileUpdateFailed: {
    id: 'Notification.profileUpdateFailed',
    defaultMessage: 'Profile details change failed'
  },
  signUpSuccess: {
    id: 'Notification.signUpSuccess',
    defaultMessage: 'Sign up was successful'
  },
  signUpFailed: {
    id: 'Notification.signUpFailed',
    defaultMessage: 'Sign up failed. Try again'
  },
  signUpCaptchaFailed: {
    id: 'Notification.signUpCaptchaFailed',
    defaultMessage: 'Looks like you are a robot. Try again'
  },
  bruteForcePolice: {
    id: 'Notification.bruteForcePolice',
    defaultMessage:
      'Account temporarily blocked due to several failed logins. Try again later or request a new password'
  },
  adminVerifiedSuccess: {
    id: 'Notification.adminVerifiedSuccess',
    defaultMessage: 'Invitation verified successfully'
  },
  adminVerifiedFailed: {
    id: 'Notification.adminVerifiedFailed',
    defaultMessage: 'Invitation verification failed'
  },
  completeVerification: {
    id: 'Notification.completeVerification',
    defaultMessage: 'Sign up to accept invitation'
  },
  addedOn: {
    defaultMessage: 'added on',
    id: 'collaboratorList.addedOn'
  },
  notLessThanSix: {
    defaultMessage: 'Length of password cannot be less than 6',
    id: 'App.Info.notLessThanSix'
  },
  addedAt: {
    defaultMessage: 'at',
    id: 'collaboratorList.addedAt'
  },
  textNo: {
    defaultMessage: 'No',
    id: 'AppText.No'
  },
  textYes: {
    defaultMessage: 'Yes',
    id: 'AppText.Yes'
  },
  formStatusUnpublished: {
    id: 'FormStatus.Unpublished',
    defaultMessage: 'Not published'
  },
  formStatusPublished: {
    id: 'FormStatus.Published',
    defaultMessage: 'Published'
  },
  formStatusNA: {
    id: 'FormStatus.NA',
    defaultMessage: 'NA'
  },
  DataCollectionTutHeader: {
    id: 'App.DataCollectionTutHeader',
    defaultMessage: 'Form created successfully'
  },
  phoneNumberPlaceholder: {
    id: 'signupForm.Phone',
    defaultMessage: 'Phone'
  },
  reportNoQuestionTitle: {
    id: 'reports.noTitle',
    defaultMessage: 'No question title'
  },
  subformRemoveBtnMsg: {
    id: 'subformSelector.RefreshMsg',
    defaultMessage: 'Remove this form'
  },
  submissionTableLabel: {
    id: 'App.FormResponsesPage.submissionTableLabel',
    defaultMessage: 'Submission Time'
  },
  responseFlagTabular: {
    id: 'App.FormResponsesPage.responseFlagTabular',
    defaultMessage: 'Status'
  },
  responseAgent: {
    id: 'App.FormResponsesPage.responseAgent',
    defaultMessage: 'Agent'
  },
  hotjarHelp: {
    id: 'Hotjar.HelpUs',
    defaultMessage: 'Help us improve by sharing your feedback.'
  },
  hotjarRate: {
    id: 'Hotjar.RateUs',
    defaultMessage: 'How would you rate your experience?'
  },
  hotjarEmail: {
    id: 'Hotjar.Email',
    defaultMessage:
      'Enter your email address if you would like to receive a follow up.'
  },
  hotjarConfirm: {
    id: 'Hotjar.Confirm',
    defaultMessage: 'Thank you for sharing your feedback with us!'
  },
  seconds: {
    id: 'AppTime.seconds',
    defaultMessage: 'secs'
  },
  minutes: {
    id: 'AppTime.minutes',
    defaultMessage: 'minutes'
  },
  hours: {
    id: 'AppTime.hours',
    defaultMessage: 'hours'
  },
  days: {
    id: 'AppTime.days',
    defaultMessage: 'days'
  },
  questionTitlePlaceholder: {
    id: 'QuestionBuilder.BuilderDetails.QuestionTitlePlaceholder',
    defaultMessage: 'Enter question'
  },
  subformTitlePlaceholder: {
    id: 'QuestionBuilder.BuilderDetails.SubformTitlePlaceholder',
    defaultMessage: 'Enter title'
  },
  questionKeywordPlaceholder: {
    id: 'QuestionBuilder.BuilderDetails.questionKeywordPlaceholder',
    defaultMessage: 'Column name'
  },
  questionRegexPlaceholder: {
    id: 'QuestionBuilder.BuilderDetails.questionRegexPlaceholder',
    defaultMessage: 'Enter Regex'
  },
  questionRegexExamplePlaceholder: {
    id: 'QuestionBuilder.BuilderDetails.questionRegexExamplePlaceholder',
    defaultMessage: 'Enter Example'
  },
  questionAttestationPlaceholder: {
    id: 'QuestionBuilder.BuilderDetails.questionAttestationPlaceholder',
    defaultMessage: 'Enter attestation here...'
  },
  questionDescription: {
    defaultMessage: 'Describe your introductory text here',
    id: 'QuestionBuilder.BuilderDetails.QuestionDescription'
  },
  sectionTitlePlaceholder: {
    defaultMessage: 'Enter section title',
    id: 'QuestionBuilder.BuilderDetails.SectionTitlePlaceholder'
  },
  QuestionHintText: {
    defaultMessage: 'Enter Hint',
    id: 'App.QuestionHintText'
  },
  MaxText: {
    defaultMessage: 'Max',
    id: 'App.MaxText'
  },
  MinText: {
    defaultMessage: 'Min',
    id: 'App.MinText'
  },
  WidthText: {
    defaultMessage: 'Width',
    id: 'App.WidthText'
  },
  HeightText: {
    defaultMessage: 'Height',
    id: 'App.HeightText'
  },
  ImageFormatsTitle: {
    defaultMessage: 'Image formats (comma separated)',
    id: 'QuestionBuilder.BuilderDetails.ImageFormatsTitle'
  },
  IntroText: {
    defaultMessage: 'Introductory Text',
    id: 'App.IntroText'
  },
  ImageText: {
    defaultMessage: 'Image',
    id: 'App.ImageText'
  },
  SingleAnsText: {
    defaultMessage: 'Multiple Choice',
    id: 'App.SingleAnsText'
  },
  NumberText: {
    defaultMessage: 'Number',
    id: 'App.NumberText'
  },
  TextText: {
    defaultMessage: 'Text',
    id: 'App.TextText'
  },
  MultipleAnsText: {
    defaultMessage: 'Multiple Choice',
    id: 'App.MultipleAnsText'
  },
  SectionText: {
    defaultMessage: 'Section',
    id: 'App.SectionText'
  },
  GPSCordText: {
    defaultMessage: 'G.P.S. Cordinate',
    id: 'App.GPSCordText'
  },
  DateText: {
    defaultMessage: 'Date',
    id: 'App.DateText'
  },
  SubFormText: {
    defaultMessage: 'Sub Form',
    id: 'App.SubFormText'
  },
  PolygonText: {
    defaultMessage: 'Polygon',
    id: 'App.PolygonText'
  },
  SignatureText: {
    defaultMessage: 'Signature',
    id: 'App.SignatureText'
  },
  EmailNotExistMsg: {
    defaultMessage: 'Sorry {email} does not exist',
    id: 'App.EmailNotExistMsg'
  },
  FingerprintText: {
    defaultMessage: 'Fingerprint',
    id: 'App.FingerprintText'
  },
  OptionTreeText: {
    defaultMessage: 'Tree',
    id: 'App.OptionTreeText'
  },
  optionExists: {
    id: 'OptionsAdder.OptionExist',
    defaultMessage: 'Option already exists!'
  },
  listHasDuplicates: {
    id: 'OptionsAdder.listHasDuplicates',
    defaultMessage: 'List has duplicates'
  },
  enterOption: {
    id: 'OptionsAdder.enterOption',
    defaultMessage: 'Please enter option to be saved'
  },
  checkOthers: {
    id: 'OptionsAdder.checkOthers',
    defaultMessage:
      "You can add 'others/other' option by checking 'Accept Others'"
  },
  addOptionPlaceholder: {
    defaultMessage: "Type and hit 'enter' or copy and paste list of options here",
    id: 'OptionsAdder.InputPlaceholder'
  },
  addTreeInputPlaceholder: {
    defaultMessage: 'Type value here',
    id: 'Tree.addTreeInputPlaceholder'
  },
  addTreeTextareaPlaceholder: {
    defaultMessage: 'Type or copy/paste list here',
    id: 'Tree.addTreeTextareaPlaceholder'
  },
  searchResponseInputPlaceHolder: {
    defaultMessage: 'Search for a response',
    id: 'Rsponses.searchResponseInputPlaceHolder'
  },
  typeHere: {
    defaultMessage: 'Type here',
    id: 'App.Text.typeHere'
  },
  chartLabelTitleInputPlaceholder: {
    defaultMessage: 'Enter chart title',
    id: 'chartLabelTitleInputPlaceholder'
  },
  chartLabelxAxisInputPlaceholder: {
    defaultMessage: 'Enter horizontal axis label',
    id: 'chartLabelxAxisInputPlaceholder'
  },
  chartLabelyAxisInputPlaceholder: {
    defaultMessage: 'Enter vertical axis label',
    id: 'chartLabelyAxisInputPlaceholder'
  },
  editOptionValuePlaceholder: {
    defaultMessage: 'Enter label for option',
    id: 'OptionsAdder.EditValuePlaceholder'
  },
  editOptionKeyPlaceholder: {
    defaultMessage: 'Enter key for option',
    id: 'OptionsAdder.EditKeyPlaceholder'
  },
  editOptionsKModalHeader: {
    defaultMessage: 'Edit option',
    id: 'OptionsAdder.editOptionsKModalHeader'
  },
  countryCodeRequiredMsg: {
    defaultMessage: 'Number not valid, begin with country code. E.g. +233',
    id: 'SignupForm.countryCodeRequiredMsg'
  },
  proportionTitle: {
    defaultMessage: 'Proportions',
    id: 'analyticType.Proportion'
  },
  proportionDesc: {
    defaultMessage: 'Visualize the compositions of a response',
    id: 'analyticType.ProportionDesc'
  },
  relationshipTitle: {
    defaultMessage: 'Comparative Analysis',
    id: 'analyticType.Relationship'
  },
  relationshipDesc: {
    defaultMessage: 'Compare responses between different groups',
    id: 'analyticType.RelationshipDesc'
  },
  summariesTitle: {
    defaultMessage: 'Visualize Variations',
    id: 'analyticType.Summaries'
  },
  summariesDesc: {
    defaultMessage:
      'View the spread of distributions, its central value, variability and outliers',
    id: 'analyticType.SummariesDesc'
  },
  descriptiveAnalysisTitle: {
    defaultMessage: 'Summary Statistics',
    id: 'analyticType.descriptiveAnalysisTitle'
  },
  descriptiveAnalysisDesc: {
    defaultMessage:
      'View descriptions of your data such as averages, counts, minimum and maximum values of your responses',
    id: 'analyticType.descriptiveAnalysisDesc'
  },
  trendsTitle: {
    defaultMessage: 'Trend Analysis',
    id: 'analyticType.Trends'
  },
  trendsDesc: {
    defaultMessage: 'Compare changes over time for various responses',
    id: 'analyticType.TrendsDesc'
  },
  gotoDash: {
    defaultMessage: 'Back to analytics',
    id: 'analytic.gotoDash'
  },
  selectQuestionProportionMsg: {
    defaultMessage: 'Select a question',
    id: 'App.Message.selectQuestion'
  },
  selectQuestionRelationMsgxAxis: {
    defaultMessage: 'Which response contains your time?',
    id: 'App.Message.selectQuestionRelationMsgxAxis'
  },
  selectQuestionRelationMsgSeries: {
    defaultMessage: 'Select another response',
    id: 'App.Message.selectQuestionRelationMsgSeries'
  },
  selectQuestionComparisonMsgxAxis: {
    defaultMessage: 'Group analysis by these responses',
    id: 'App.Message.selectQuestionComparisonMsgxAxis'
  },
  dataTypeSelectedLabel: {
    defaultMessage: 'Generate chart using: ',
    id: 'App.Message.dataTypeSelectedLabel'
  },
  multiSelectYAxis: {
    defaultMessage: 'Select at least two responses',
    id: 'App.Message.multiSelectYAxis'
  },
  selectQuestionComparisonMsgyAxis: {
    defaultMessage: 'Select a response',
    id: 'App.Message.selectQuestionComparisonMsgyAxis'
  },
  parametersSelectTitle: {
    defaultMessage: 'Select parameters',
    id: 'App.Message.parametersSelectTitle'
  },
  selectQuestionComparisonMsgSeries: {
    defaultMessage: 'Select another response to compare with',
    id: 'App.Message.selectQuestionComparisonMsgSeries'
  },
  selectQuestionSummaryMsgxAxis: {
    defaultMessage: 'Group data by',
    id: 'App.Message.selectQuestionSummaryMsgxAxis'
  },
  selectQuestionSummaryMsgyAxis: {
    defaultMessage: 'Select responses to be plotted',
    id: 'App.Message.selectQuestionSummaryMsgyAxis'
  },
  clickBelowToSelect: {
    defaultMessage: 'Click below to select question',
    id: 'App.Message.clickBelowToSelect'
  },
  clickBelowToSelectParams: {
    defaultMessage: 'Click below to select/deselect parameters',
    id: 'App.Message.clickBelowToSelectParams'
  },
  clickToReveal: {
    defaultMessage: 'Click to reveal questions',
    id: 'App.Message.clickToReveal'
  },
  enterOptionOnly: {
    defaultMessage: 'Enter Option',
    id: 'App.Message.enterOptionOnly'
  },
  AddColumnTitle: {
    defaultMessage: 'Double click to add title',
    id: 'App.Message.AddColumnTitle'
  },
  optionParamsLabelSum: {
    defaultMessage: 'Sum',
    id: 'App.optionParamsLabelSum'
  },
  optionParamsDescSum: {
    defaultMessage: 'Total Value',
    id: 'App.optionParamsDescSum'
  },
  optionParamsLabelCount: {
    defaultMessage: 'Count',
    id: 'App.optionParamsLabelCount'
  },
  optionParamsDescCount: {
    defaultMessage: 'Number of occurrances in your response',
    id: 'App.optionParamsDescCount'
  },
  optionParamsLabelMode: {
    defaultMessage: 'Mode',
    id: 'App.optionParamsLabelMode'
  },
  optionParamsDescMode: {
    defaultMessage: 'Appears most often in your response',
    id: 'App.optionParamsDescMode'
  },
  optionParamsLabelMaximum: {
    defaultMessage: 'Maximum',
    id: 'App.optionParamsLabelMaximum'
  },
  optionParamsDescMaximum: {
    defaultMessage: 'Highest value in your response',
    id: 'App.optionParamsDescMaximum'
  },
  optionParamsLabelMinimum: {
    defaultMessage: 'Minimum',
    id: 'App.optionParamsLabelMinimum'
  },
  optionParamsDescMinimum: {
    defaultMessage: 'Least value in your response',
    id: 'App.optionParamsDescMinimum'
  },
  optionParamsLabelMedian: {
    defaultMessage: 'Median',
    id: 'App.optionParamsLabelMedian'
  },
  optionParamsDescMedian: {
    defaultMessage: 'Central Value in your response',
    id: 'App.optionParamsDescMedian'
  },
  optionParamsLabelAverage: {
    defaultMessage: 'Average',
    id: 'App.optionParamsLabelAverage'
  },
  optionParamsDescAverage: {
    defaultMessage: 'Calculated central value in your response',
    id: 'App.optionParamsDescAverage'
  },
  optionParamsLabelStD: {
    defaultMessage: 'Standard Deviation',
    id: 'App.optionParamsLabelStD'
  },
  optionParamsDescStD: {
    defaultMessage: 'How far your response is from the average',
    id: 'App.optionParamsDescStD'
  },
  analyticTypeDetailsProportionLabel: {
    defaultMessage: 'Proportions',
    id: 'App.analyticTypeDetailsProportionLabel'
  },
  analyticTypeDetailsProportionDesc: {
    defaultMessage: 'View a chart for Proportion on the form.',
    id: 'App.analyticTypeDetailsProportionDesc'
  },
  analyticTypeDetailsTrendOverTimeLabel: {
    defaultMessage: 'Trend Analysis',
    id: 'App.analyticTypeDetailsTrendOverTimeLabel'
  },
  analyticTypeDetailsTrendOverTimeDesc: {
    defaultMessage: 'View a chart for trend Analysis on the form.',
    id: 'App.analyticTypeDetailsTrendOverTimeDesc'
  },
  analyticTypeDetailsRelationshipLabel: {
    defaultMessage: 'Comparative Analysis',
    id: 'App.analyticTypeDetailsRelationshipLabel'
  },
  analyticTypeDetailsRelationshipDesc: {
    defaultMessage: 'View a chart for Comparative Analysis on the form.',
    id: 'App.analyticTypeDetailsRelationshipDesc'
  },
  analyticTypeDetailsSummariesLabel: {
    defaultMessage: 'Visualize Variations',
    id: 'App.analyticTypeDetailsSummariesLabel'
  },
  analyticTypeDetailsSummariesDesc: {
    defaultMessage: 'View a chart for Visual Variations on the form.',
    id: 'App.analyticTypeDetailsSummariesDesc'
  },
  analyticTypeDetailsdescAnalysisLabel: {
    defaultMessage: 'Descriptive Statistics',
    id: 'App.analyticTypeDetailsdescAnalysisLabel'
  },
  analyticTypeDetailsdescAnalysisDesc: {
    defaultMessage: 'View a list of descriptive analysis on the form.',
    id: 'App.analyticTypeDetailsdescAnalysisDesc'
  },
  responseSummaryLabel: {
    defaultMessage: 'Response Summary',
    id: 'App.ReportType.responseSummaryLabel'
  },
  responseSummaryDescription: {
    defaultMessage:
      'View  the counts of your responses. Get informed of the number of times a response occured and skipped in your data set',
    id: 'App.ReportType.responseSummaryDescription'
  },
  agentLocationLabel: {
    defaultMessage: 'Agent Location',
    id: 'App.ReportType.agentLocationLabel'
  },
  agentLocationDescription: {
    defaultMessage:
      'Know the exact last location of your agents. This allow you to keep an eye on your agents wherever they are.',
    id: 'App.ReportType.agentLocationDescription'
  },
  agentPerformanceLabel: {
    defaultMessage: 'Agent Performance',
    id: 'App.ReportType.agentPerformanceLabel'
  },
  agentPerformanceDescription: {
    defaultMessage: 'Analyze the performance of your agents over time. Easily identify best and worst performing agents with this report',
    id: 'App.ReportType.agentPerformanceDescription'
  },
  polygonResponsesLabel: {
    defaultMessage: 'Maps',
    id: 'App.ReportType.polygonResponsesLabel'
  },
  polygonResponsesDescription: {
    defaultMessage: 'View GIS and Polygon responses on a map. With this you can make informed decisions based on locations',
    id: 'App.ReportType.polygonResponsesDescription'
  },
  reviewActionAccept: {
    defaultMessage: 'No Action',
    id: 'App.reviewActionAccept'
  },
  reviewActionReject: {
    defaultMessage: 'Reject',
    id: 'App.text.Reject'
  },
  reviewActionOther: {
    defaultMessage: 'Reject with a comment',
    id: 'App.reviewActionOther'
  },
  submittedBy: {
    defaultMessage: 'Submitted by',
    id: 'App.DetailedResponse.submittedBy'
  },
  dateSubmitted: {
    defaultMessage: 'Date submitted',
    id: 'App.DetailedResponse.dateSubmitted'
  },
  Responselocation: {
    defaultMessage: 'Response location (GPS)',
    id: 'App.DetailedResponse.Responselocation'
  },
  Responseduration: {
    defaultMessage: 'Response duration',
    id: 'App.DetailedResponse.Responseduration'
  },
  seeLog: {
    defaultMessage: 'See activity log',
    id: 'App.DetailedResponse.seeLog'
  },
  errorManagement: {
    defaultMessage: 'Error Management',
    id: 'App.DetailedResponse.errorManagement'
  },
  comments: {
    defaultMessage: 'Comments',
    id: 'App.Text.Comments'
  },
  addtionalComment: {
    defaultMessage: 'Additional comments (if any)',
    id: 'App.DetailedResponse.addtionalComment'
  },
  enterComment: {
    defaultMessage: 'Enter comment',
    id: 'App.DetailedResponse.enterComment'
  },
  addedBy: {
    defaultMessage: 'Added by',
    id: 'App.DetailedResponse.addedBy'
  },
  status: {
    defaultMessage: 'Status',
    id: 'App.Text.Status'
  },
  close: {
    defaultMessage: 'Close',
    id: 'App.Text.Close'
  },
  addedcomment: {
    defaultMessage: 'added a comment on',
    id: 'App.DetailedResponse.addedcomment'
  },
  changedStatus: {
    defaultMessage: 'changed the status from',
    id: 'App.DetailedResponse.changedStatus'
  },
  on: {
    defaultMessage: 'on',
    id: 'App.Text.On'
  },
  to: {
    defaultMessage: 'to',
    id: 'App.Text.To'
  },
  activityLog: {
    defaultMessage: 'Activity log',
    id: 'App.DetailedResponse.activityLog'
  },
  notes: {
    defaultMessage: 'Notes',
    id: 'App.DetailedResponse.notes'
  },
  noActivityLog: {
    defaultMessage: 'No activity log',
    id: 'App.DetailedResponse.noActivityLog'
  },
  commentLog: {
    defaultMessage: 'Comment log',
    id: 'App.DetailedResponse.commentLog'
  },
  noComments: {
    defaultMessage: 'No comments',
    id: 'App.DetailedResponse.noComments'
  },
  formErrorMsgInputEmail: {
    defaultMessage: 'Please input your email',
    id: 'App.Form.formErrorMsgInputEmail'
  },
  formErrorMsgCheckEmail: {
    defaultMessage: 'Please check provided email',
    id: 'App.Form.formErrorMsgCheckEmail'
  },
  formErrorMsgInputPassword: {
    defaultMessage: 'Please input your password',
    id: 'App.Form.formErrorMsgInputPassword'
  },
  formErrorMsgConfirmPassword: {
    defaultMessage: 'Please confirm password',
    id: 'App.Form.formErrorMsgConfirmPassword'
  },
  formErrorMsgInputFirstName: {
    defaultMessage: 'Please input your first name',
    id: 'App.Form.formErrorMsgInputFirstName'
  },
  formErrorMsgInputLastName: {
    defaultMessage: 'Please input your last name',
    id: 'App.Form.formErrorMsgInputLastName'
  },
  formErrorMsgInputOrgName: {
    defaultMessage: 'Please input your organisation',
    id: 'App.Form.formErrorMsgInputOrgName'
  },
  formErrorMsgInputPhoneNumber: {
    defaultMessage: 'Please input your phone number',
    id: 'App.Form.formErrorMsgInputPhoneNumber'
  },
  formErrorMsgNotValidPhoneNumber: {
    defaultMessage: 'Not a valid phone number',
    id: 'App.Form.formErrorMsgNotValidPhoneNumber'
  },
  formErrorMsgMismatchPassword: {
    defaultMessage: 'Passwords do not match',
    id: 'App.Form.formErrorMsgMismatchPassword'
  },
  formEditSaveSuccess: {
    defaultMessage: 'Edited response saved successfully',
    id: 'App.Responses.formEditSuccess'
  },
  formEditSaveFailed: {
    defaultMessage: 'Saving edited response failed',
    id: 'App.Responses.formEditFailed'
  },
  createReviewExerciseSuccess: {
    defaultMessage: 'Todo created successfully',
    id: 'App.Responses.createReviewExerciseSuccess'
  },
  createReviewExerciseFailed: {
    defaultMessage: 'Todo creation failed',
    id: 'App.Responses.createReviewExerciseFailed'
  },
  fetchReviewExercisesFailed: {
    defaultMessage: 'To Dos fetch failed',
    id: 'App.Responses.fetchReviewExercisesFailed'
  },
  fetchReviewExerciseFailed: {
    defaultMessage: 'Todo fetch failed',
    id: 'App.Responses.fetchReviewExerciseFailed'
  },
  declineInviteFailed: {
    defaultMessage: 'declining invite failed',
    id: 'App.Responses.declineInviteFailed'
  },
  approveInviteFailed: {
    defaultMessage: 'declining invite failed',
    id: 'App.Responses.approveInviteFailed'
  },
  billingTransactionFromBlackBoxSuccess: {
    defaultMessage: 'Transaction successful',
    id: 'ApplicationCache.Notification.billingTransactionFromBlackBoxSuccess'
  },
  billingTransactionFromBlackBoxFailure: {
    defaultMessage: 'Transaction failed',
    id: 'ApplicationCache.Notification.billingTransactionFromBlackBoxFailure'
  },
  'App.Text.Forms': {
    id: 'App.Text.Forms',
    defaultMessage: 'Forms'
  },
  'App.Text.Questions': {
    id: 'App.Text.Questions',
    defaultMessage: 'Questions'
  },
  'App.Text.month': {
    id: 'App.Text.month',
    defaultMessage: 'month'
  },
  'App.Text.ReviewExercise': {
    id: 'App.Text.ReviewExercise',
    defaultMessage: 'Review Exercise'
  },
  'LeaveForm.Confirm': {
    id: 'LeaveForm.Confirm',
    defaultMessage: 'Do you want to leave'
  },
  'LeaveForm.ThisForm': {
    id: 'LeaveForm.ThisForm',
    defaultMessage: 'this form'
  },
  'LeaveForm.ThisPage': {
    id: 'LeaveForm.ThisPage',
    defaultMessage: 'this page'
  },
  'Billing.Currency.Placeholder': {
    id: 'Billing.Currency.Placeholder',
    defaultMessage: 'Choose a currency'
  },
  'Billing.Currency.Choose': {
    id: 'Billing.Currency.Choose',
    defaultMessage: 'Choose a payment mode'
  },
  'HeadingForBranch': {
    id: 'HeadingForBranch',
    defaultMessage: 'Provide heading for this branch'
  },
  AccessibleToAll: {
    id: 'AccessibleToAll',
    defaultMessage: 'Accessible to all'
  },
  DefineRulesFor: {
    id: 'DefineRulesFor',
    defaultMessage: 'Define the rules for'
  },
  'Billing.Click2Upgrade': {
    id: 'Billing.Click2Upgrade',
    defaultMessage: 'Click to upgrade to Enterprise Plan'
  },
  'Billing.Click2Upgrade.Description': {
    id: 'Billing.Click2Upgrade.Description',
    defaultMessage: 'for unlimited responses, unlimited forms and a dedicated account manager'
  },
  'Label.Industry': {
    id: 'Label.Industry',
    defaultMessage: 'Industry'
  },
  'Label.Industry.Required': {
    id: 'Label.Industry.Required',
    defaultMessage: 'Please provide industry',
  },
  'Label.Title': {
    id: 'Label.Title',
    defaultMessage: 'Title'
  },
  'Label.Description': {
    id: 'Label.Description',
    defaultMessage: 'Description'
  },
  'TemplateText.FormTitle': {
    id: 'TemplateText.FormTitle',
    defaultMessage: 'Form title'
  },
  'TemplateText.Category': {
    id: 'TemplateText.Category',
    defaultMessage: 'Category'
  },
  'TemplateText.SetAsTemplate': {
    id: 'TemplateText.SetAsTemplate',
    defaultMessage: 'Set as template'
  },
  'Button.Proceed': {
    id: 'Button.Proceed',
    defaultMessage: 'Proceed'
  },
  'Button.Cancel': {
    id: 'Button.Cancel',
    defaultMessage: 'Cancel'
  },
  'Todo.ItemsDelete.Success': {
    id: 'Todo.ItemsDelete.Success',
    defaultMessage: 'Item(s) deleted'
  },
  'Todo.ItemsDelete.Failed': {
    id: 'Todo.ItemsDelete.Failed',
    defaultMessage: 'Unable to delete item(s)'
  },
  'Todo.ItemsUpdate.Success': {
    id: 'Todo.ItemsUpdate.Success',
    defaultMessage: 'Todo list updated with success'
  },
  'App.Text.Profiled': {
    id: 'App.Text.Profiled',
    defaultMessage: 'Profiled'
  },
  PercentageProfiledRecord: {
    id: 'PercentageProfiledRecord',
    defaultMessage: 'Percentage of profiled record'
  },
  'Todo.Description.Placeholder': {
    id: 'Todo.Description.Placeholder',
    defaultMessage: 'e.g. Household Members'
  },
  'Label.Name': {
    id: 'Label.Name',
    defaultMessage: 'Name'
  },
  'Label.Email': {
    id: 'Label.Email',
    defaultMessage: 'Email address'
  },
  'App.SettingsPasswordResetPage.Password': {
    id: 'App.SettingsPasswordResetPage.Password',
    defaultMessage: 'Enter Password'
  },
  'App.SettingsPasswordResetPage.Cpassword': {
    id: 'App.SettingsPasswordResetPage.Cpassword',
    defaultMessage: 'Confirm password'
  },
  'Label.ProjectName': {
    id: 'Label.ProjectName',
    defaultMessage: 'Project Name'
  },
  'Label.ShortName': {
    id: 'Label.ShortName',
    defaultMessage: 'Short name'
  },
  'Settings.ProjectDetails.ProjectDescription': {
    id: 'Settings.ProjectDetails.ProjectDescription',
    defaultMessage: 'Project Description'
  },
  'ChangeSubscription.Failed': {
    id: 'ChangeSubscription.Failed',
    defaultMessage: 'Unable to change your subscription at the moment'
  },
  'Subscription.Extend': {
    id: 'Subscription.Extend',
    defaultMessage: 'Extend'
  },
  'Subscription.Activate': {
    id: 'Subscription.Activate',
    defaultMessage: 'Activate'
  },
  'Billing.Enterprise': {
    id: 'Billing.Enterprise',
    defaultMessage: 'Enterprise'
  },
  'EnterSearchCriteria': {
    id: 'EnterSearchCriteria',
    defaultMessage: 'Enter search criteria'
  },
  'UntitledQuestion': {
    id: 'UntitledQuestion',
    defaultMessage: 'Untitled Question'
  },
  'ViewDetails': {
    id: 'ViewDetails',
    defaultMessage: 'View Details'
  },
  'ChooseDataSource': {
    id: 'ChooseDataSource',
    defaultMessage: 'Choose the source of data'
  },
  Fields: {
    id: 'Fields',
    defaultMessage: 'Fields'
  },
  WriteYourNoteHere: {
    id: 'WriteYourNoteHere',
    defaultMessage: 'Write your note here...'
  },
  'TagCloud.Placeholder': {
    id: 'TagCloud.Placeholder',
    defaultMessage: 'Type options here and press \'enter\''
  },
  UnnamedArea: {
    id: 'UnnamedArea',
    defaultMessage: 'Unnamed Area'
  },
  'App.text.Enter.Message': {
    id: 'App.text.Enter.Message',
    defaultMessage: 'Enter place'
  },
  'Geo.AreaName.Placeholder': {
    id: 'Geo.AreaName.Placeholder',
    defaultMessage: 'Eg. Accra West District'
  },
  SelectPage: {
    id: 'SelectPage',
    defaultMessage: 'Select a page'
  },
  'EnterPageName': {
    id: 'EnterPageName',
    defaultMessage: 'Enter page name'
  },
  'Delete.Question.Confirm': {
    id: 'Delete.Question.Confirm',
    defaultMessage: 'Are you sure delete this question?'
  },
  'Yes': {
    id: 'Yes', defaultMessage: 'Yes'
  },
  'No': {
    id: 'No', defaultMessage: 'No'
  },
  'Label.WhatisQuestion': {
    id: 'Label.WhatisQuestion',
    defaultMessage: 'What is your question?'
  },
  'ValidationsSkipLogics': {
    id: 'ValidationsSkipLogics',
    defaultMessage: 'Validations & Skip Logics'
  },
  'DeleteQuestion': {
    id: 'DeleteQuestion',
    defaultMessage: 'Delete Question'
  },
  MinDate: {
    id: 'MinDate',
    defaultMessage: 'MinDate'
  },
  MaxDate: {
    id: 'MaxDate',
    defaultMessage: 'MaxDate'
  },
  'SelectOperator': {
    id: 'SelectOperator',
    defaultMessage: 'Select an operator'
  },
  ExampleBoyGirl: {
    id: 'ExampleBoyGirl',
    defaultMessage: 'Eg. boy, girl'
  },
  TypeInValue: {
    id: 'TypeInValue',
    defaultMessage: 'Type in value'
  },
  'vRules.ErrorMessage1': {
    id: 'vRules.ErrorMessage1',
    defaultMessage: 'One or more of the questions in the rules for this widget has been deleted or unavailable. Correct the issue.'
  },
  SelectSubForm: {
    id: 'SelectSubForm',
    defaultMessage: 'Select a sub form'
  },
  SelectQuestion: {
    id: 'SelectQuestion',
    defaultMessage: 'Select a question'
  },
  'App.Text.Search': {
    id: 'App.Text.Search',
    defaultMessage: 'Search'
  },
  SearchAgents: {
    id: 'SearchAgents',
    defaultMessage: 'Search agents'
  },
  SomethingWentWrong: {
    id: 'SomethingWentWrong',
    defaultMessage: 'Something went wrong. Retry'
  },
  DownloadingResponseImages: {
    id: 'DownloadingResponseImages',
    defaultMessage: 'Downloading response images'
  },
  ViewSignature: {
    id: 'ViewSignature',
    defaultMessage: 'View Signature'
  },
  ViewImage: {
    id: 'ViewImage',
    defaultMessage: 'View Image'
  },
  'App.FormResponsesPage.startDateTableLabel': {
    id: 'App.FormResponsesPage.startDateTableLabel',
    defaultMessage: 'Start time'
  },
  'App.FormResponsesPage.endDateTableLabel': {
    id: 'App.FormResponsesPage.endDateTableLabel',
    defaultMessage: 'End time'
  },
  AddAccessCodeSuccess: {
    id: 'AddAccessCodeSuccess',
    defaultMessage: 'Access code created successfully'
  },
  AddAccessCodeFailed: {
    id: 'AddAccessCodeFailed',
    defaultMessage: 'Unable to add access code'
  },
  EditAccessCodeSuccess: {
    id: 'EditAccessCodeSuccess',
    defaultMessage: 'Access code edited successfully'
  },
  EditAccessCodeFailed: {
    id: 'EditAccessCodeFailed',
    defaultMessage: 'Unable to edit access code'
  },
  'formSettings.AccessCodesPlaceho': {
    id: 'formSettings.AccessCodesPlaceho',
    defaultMessage: 'Enter a name for your access code'
  },
  'App.Text.AddStatus': {
    id: 'App.Text.AddStatus',
    defaultMessage: 'Add status'
  },
  'App.Text.AddKeyword': {
    id: 'App.Text.AddKeyword',
    defaultMessage: 'Add Keyword'
  },
  'App.ChooseOne': {
    id: 'App.ChooseOne',
    defaultMessage: 'Choose one'
  },
  'App.NoOption': {
    id: 'App.NoOption',
    defaultMessage: 'No option'
  },
  'formSettings.SupervisorPlaceho': {
    id: 'formSettings.SupervisorPlaceho',
    defaultMessage: 'Data sample size to be verified by supervisors'
  },
  'formSettings.AccessCodes.SaveCoverage': {
    id: 'formSettings.AccessCodes.SaveCoverage',
    defaultMessage: 'Save Coverage'
  },
  'formSettings.SupervisorPlaceho.EnterName': {
    id: 'formSettings.SupervisorPlaceho.EnterName',
    defaultMessage: 'Enter a name for your supervisor'
  },
  'formSettings.SupervisorCommentPlaceho': {
    id: 'formSettings.SupervisorCommentPlaceho',
    defaultMessage: 'Enter an error comment'
  },
  'InputSearchText': {
    id: 'InputSearchText',
    defaultMessage: 'Input search text'
  },
  'App.Text.Required': {
    id: 'App.Text.Required',
    defaultMessage: 'Required'
  },
  'SignUp.Email.Invalid': {
    id: 'SignUp.Email.Invalid',
    defaultMessage: 'Invalid email'
  },
  'SignUp.Password.Math': {
    id: 'SignUp.Password.Math',
    defaultMessage: 'Passwords do not match'
  },
  'SignUp.Password.Short': {
    id: 'SignUp.Password.Short',
    defaultMessage: 'At least six characters long'
  },
  'ExpectedResponses': {
    id: 'ExpectedResponses',
    defaultMessage: 'Expected Responses'
  },
  'App.text.Required': {
    id: 'App.text.Required',
    defaultMessage: 'Required'
  },
  'Todo.AssignToAgent.Title': {
    id: 'Todo.AssignToAgent.Title',
    defaultMessage: 'Assign to codes'
  },
  'Todo.RemoveItems': {
    id: 'Todo.RemoveItems',
    defaultMessage: 'Remove Items'
  },
  'Todo.RemoveItems.Description': {
    id: 'Todo.RemoveItems.Description',
    defaultMessage: 'items have been selected for removal. Would you like to proceed?'
  },
  'Todo.DeleteAll': {
    id: 'Todo.DeleteAll',
    defaultMessage: 'Delete all items'
  },
  'Todo.DeleteAll.Description': {
    id: 'Todo.DeleteAll.Description',
    defaultMessage: 'This action will delete all items in this list. Do you wish to proceed?'
  },
  'RestoreResponseTitle': {
    id: 'RestoreResponseTitle',
    defaultMessage: 'Restore response?'
  },
  'RestoreResponseContent': {
    id: 'RestoreResponseContent',
    defaultMessage: 'This will restore an earlier submission of responses and undo recent changes made by the agent which cause some important information to be lost. Continue?'
  },
  'YesRestore': {
    id: 'YesRestore',
    defaultMessage: 'Yes, Restore'
  },
  'DeleteResponseConfirm': {
    id: 'DeleteResponseConfirm',
    defaultMessage: 'Are you sure you want to delete selected responses?'
  },
});
