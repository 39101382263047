import { sendMetricNoDispatch } from '../actions/prom';

export default (instance, callback) => {
  instance.interceptors.request.use(request => {
    request.ts = performance.now();
    return request;
  });

  instance.interceptors.response.use(response => {
    response.config.custMetric &&
      sendMetricNoDispatch(
        response.config.custMetric.metricType,
        response.config.custMetric.metric,
        response.config.custMetric.help,
        response.config.custMetric.labels,
        Number(performance.now() - response.config.ts)
      );
    return response;
  });
};
