export default (state = { lang: 'en' }, action) => {
  switch (action.type) {
    case 'CHANGE_LANG':
      return {
        ...state,
        lang: action.lang || 'en'
      };

    case 'CHANGE_LANG_IN_SETTINGS':
      return {
        ...state,
        lang: action.lang || 'en'
      };
    default:
      return state;
  }
};
