import { HIDE_MODAL, SHOW_MODAL, SET_PAGE_SUB_TITLE } from '../utils/types';

export const showModal = (modalName, modalProps) => {
  return dispatch => {
    dispatch({
      type: SHOW_MODAL,
      modalName, // custom name to determine which modal to call
      modalProps // object of props to pass to modal
    });
  };
};

export const hideModal = () => {
  return dispatch => {
    dispatch({
      type: HIDE_MODAL
    });
  };
};

export const setPageSubTitle = subTitle => {
  return dispatch => {
    dispatch({
      type: SET_PAGE_SUB_TITLE,
      subTitle
    });
  };
};

export const toggleInfoCard = (showAppInfoCard, info) => {
  return dispatch => {
    if (showAppInfoCard) {
      return dispatch({
        type: 'SHOW_INFOCARD',
        info
      });
    }

    return dispatch({
      type: 'HIDE_INFOCARD'
    });
  };
};


export const setSubIndex = (index) => {
  return dispatch => {
    return dispatch({
      type: 'SUB_INDEX', index,
    });
  };
};