export const FETCH_REVIEW_EXERCISE_LIST_SUMMARY = 'FETCH_REVIEW_EXERCISE_LIST_SUMMARY';
export const FETCH_REVIEW_EXERCISE_LIST_SUMMARY_SUCCESS = 'FETCH_REVIEW_EXERCISE_LIST_SUMMARY_SUCCESS';
export const FETCH_REVIEW_EXERCISE_LIST_SUMMARY_FAILED = 'FETCH_REVIEW_EXERCISE_LIST_SUMMARY_FAILED';

export const FETCH_ACTIVE_REVIEW_EXERCISE = 'FETCH_ACTIVE_REVIEW_EXERCISE';
export const FETCH_ACTIVE_REVIEW_EXERCISE_SUCCESS = 'FETCH_ACTIVE_REVIEW_EXERCISE_SUCCESS';
export const FETCH_ACTIVE_REVIEW_EXERCISE_FAILED = 'FETCH_ACTIVE_REVIEW_EXERCISE_FAILED';

export const DELETE_REVIEW_EXERCISE = 'DELETE_REVIEW_EXERCISE';
export const DELETE_REVIEW_EXERCISE_SUCCESS = 'DELETE_REVIEW_EXERCISE_SUCCESS';
export const DELETE_REVIEW_EXERCISE_FAILED = 'DELETE_REVIEW_EXERCISE_FAILED';

export const UPDATE_ACTIVE_REVIEW_EXERCISE = 'UPDATE_ACTIVE_REVIEW_EXERCISE';
export const UPDATE_ACTIVE_REVIEW_EXERCISE_SUCCESS = 'UPDATE_ACTIVE_REVIEW_EXERCISE_SUCCESS';
export const UPDATE_ACTIVE_REVIEW_EXERCISE_FAILED = 'UPDATE_ACTIVE_REVIEW_EXERCISE_FAILED';

export const FETCH_REVIEW_EXERCISES = 'FETCH_REVIEW_EXERCISES';
export const FETCH_REVIEW_EXERCISES_SUCCESS = 'FETCH_REVIEW_EXERCISES_SUCCESS';
export const FETCH_REVIEW_EXERCISES_FAILED = 'FETCH_REVIEW_EXERCISES_FAILED';

export const FETCH_RESTRICTIONS = 'FETCH_RESTRICTIONS';
export const FETCH_RESTRICTIONS_SUCCESS = 'FETCH_RESTRICTIONS_SUCCESS';
export const FETCH_RESTRICTIONS_FAILED = 'FETCH_RESTRICTIONS_FAILED';
