import {
  FETCHING_ADMINS,
  FETCHING_ADMINS_SUCCESS,
  FETCHING_ADMINS_FAILED,
  INVITING_ADMIN,
  INVITING_ADMIN_SUCCESS,
  INVITING_ADMIN_FAILED,
  REMOVE_ADMIN,
  REMOVE_ADMIN_SUCCESS,
  REMOVE_ADMIN_FAILED,
  VERIFYING_ADMIN,
  VERIFYING_ADMIN_SUCCESS,
  VERIFYING_ADMIN_FAILED,
  LOGGING_OUT_SUCCESS,
  UPDATING_ADMIN_DETAILS,
  UPDATING_ADMIN_DETAILS_FAILED,
  UPDATING_ADMIN_DETAILS_SUCCESS,
  GET_BILLING_RECORDS,
  GET_BILLING_RECORDS_FAILED,
  GET_BILLING_RECORDS_SUCCESS
} from '../../utils/types';
const initialState = {
  admins: [],
  BTransactions: [],
  BTransactionsMeta: undefined,
  fetching_admins: true,
  removing_admin: false,
  inviting_admin: false,
  verifying_admin: false,
  loadingBTransactions: false,
  newAccountInfo: {},
  newAccount: false,
  updating_admin: false,
  BtransactionsError: undefined,
  nextBillableDetails: undefined,
  nextBillableDetailsLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGGING_OUT_SUCCESS:
      return {
        ...initialState
      };
    case FETCHING_ADMINS:
      return {
        ...state,
        fetching_admins: true
      };
    case FETCHING_ADMINS_SUCCESS:
      return {
        ...state,
        admins: action.data,
        fetching_admins: false
      };
    case FETCHING_ADMINS_FAILED:
      return {
        ...state,
        fetching_admins: false
      };
    case INVITING_ADMIN:
      return {
        ...state,
        inviting_admin: true
      };
    case INVITING_ADMIN_SUCCESS:
      return {
        ...state,
        inviting_admin: false,
        admins: [action.admin, ...state.admins]
      };
    case INVITING_ADMIN_FAILED:
      return {
        ...state,
        inviting_admin: false
      };
    case REMOVE_ADMIN:
      return {
        ...state,
        removing_admin: true
      };
    case REMOVE_ADMIN_SUCCESS:
      return {
        ...state,
        admins: [...state.admins.filter(admin => admin.id !== action.id)],
        removing_admin: false
      };
    case REMOVE_ADMIN_FAILED:
      return {
        ...state,
        removing_admin: false
      };
    case VERIFYING_ADMIN:
      return {
        ...state,
        verifying_admin: true
      };
    case VERIFYING_ADMIN_SUCCESS:
      return {
        ...state,
        verifying_admin: false,
        newAccountInfo: action.newUser ? action.newUser : {},
        newAccount: action.newAccount ? action.newAccount : false
      };
    case VERIFYING_ADMIN_FAILED:
      return {
        ...state,
        verifying_admin: false
      };

    case UPDATING_ADMIN_DETAILS:
      return {
        ...state,
        updating_admin: true
      };
    case UPDATING_ADMIN_DETAILS_SUCCESS:
      return {
        ...state,
        admins: [
          ...state.admins.map(admin =>
            admin.id === action.admin.id ? action.admin : admin
          )
        ],
        updating_admin: false
      };
    case UPDATING_ADMIN_DETAILS_FAILED:
      return {
        ...state,
        updating_admin: false
      };
    case GET_BILLING_RECORDS:
      return {
        ...state,
        loadingBTransactions: true
      };
    case GET_BILLING_RECORDS_SUCCESS:
      return {
        ...state,
        BTransactions: action.data,
        BTransactionsMeta: action.meta,
        loadingBTransactions: false
      };
    case GET_BILLING_RECORDS_FAILED:
      return {
        ...state,
        loadingBTransactions: false,
        BtransactionsError: action.error
      };
    case 'GET_ESTIMATED_BILL':
      return {
        ...state,
        nextBillableDetailsLoading: true
      };
    case 'GET_ESTIMATED_BILL_SUCCESS':
      return {
        ...state,
        nextBillableDetails: action.data,
        nextBillableDetailsLoading: false
      };
    case 'GET_ESTIMATED_BILL_FAILED':
      return {
        ...state,
        nextBillableDetailsLoading: false
      };
    default:
      return state;
  }
};
