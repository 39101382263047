import { SHOW_MODAL, HIDE_MODAL, SET_PAGE_SUB_TITLE } from '../utils/types';
const initialState = {
  modalType: null,
  modalProps: {},
  modalShow: false,
  subTitle: '',
  showAppInfoCard: false,
  AppInfoCard: '',
  subIndex: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {

    case SHOW_MODAL:
      return {
        ...state,
        modalName: action.modalName,
        modalShow: true,
        modalProps: action.modalProps
      };

    case HIDE_MODAL:
      return {
        ...state,
        modalName: '',
        modalShow: false,
        modalProps: {}
      };

    case 'SHOW_INFOCARD':
      return {
        ...state,
        showAppInfoCard: true,
        AppInfoCard: action.info
      };

    case 'HIDE_INFOCARD':
      return {
        ...state,
        showAppInfoCard: false,
        AppInfoCard: ''
      };

    case SET_PAGE_SUB_TITLE:
      return {
        ...state,
        subTitle: action.subTitle
      };

    case 'SUB_INDEX':
      return {
        ...state,
        subIndex: action.index,
      };

    default:
      return state;
  }
};
