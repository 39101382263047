import { getCookie } from '../utils/helpers';
import {
  LOGGING_IN,
  LOGGING_IN_FAILED,
  LOGGING_IN_SUCCESS,
  FETCHING_USER,
  FETCHING_USER_SUCCESS,
  FETCHING_USER_FAILED,
  UPDATING_USER,
  UPDATING_USER_SUCCESS,
  UPDATING_USER_FAILED,
  UPDATING_PASSWORD,
  UPDATING_PASSWORD_FAILED,
  UPDATING_PASSWORD_SUCCESS,
  LOGGING_OUT,
  LOGGING_OUT_SUCCESS,
  CREATING_ACCOUNT,
  CREATING_ACCOUNT_SUCCESS,
  CREATING_ACCOUNT_FAILED,
  RESETTING_PASSWORD,
  RESETTING_PASSWORD_SUCCESS,
  RESETTING_PASSWORD_FAILED,
  VALIDATING_TOKEN,
  VALIDATING_TOKEN_SUCCESS,
  VALIDATING_TOKEN_FAILED,
  ADD_ADMIN,
  ADD_ADMIN_FAILED,
  ADD_ADMIN_SUCCESS
} from '../utils/types';

const initialState = {
  validating: true,
  validx: null,
  validUser: {},
  resetting: false,
  reset_msg: '',
  reset_success: null,
  loading: false,
  token: null,
  user: null, // {}
  fetching_user_error: '',
  fetching_user: true,
  error: false,
  error_msg: '',
  isUpdatingUser: false,
  isUpdatingPassword: false,
  isUpdatingUserSuccess: false,
  isUpdatingPasswordSuccess: false,
  userId: null,
  isAuthenticated: getCookie('user_token') ? true : false,
  sentResetEmail: false,
  passResetMessage: '',
  tokenUserId: null,
  isValidating: false,
  isTokenValid: false,
  isPasswordResetSuccess: false,
  account_verifying: true,
  account_verify_status: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGGING_IN:
      return {
        ...state,
        loading: true
      };
    case LOGGING_IN_SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.responseData.token,
        user: action.responseData
      };
    case LOGGING_IN_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.error_msg
      };
    case FETCHING_USER:
      return {
        ...state,
        fetching_user: true
      };
    case FETCHING_USER_SUCCESS:
      return { ...state, user: action.user, fetching_user: false };
    case FETCHING_USER_FAILED:
      return {
        ...state,
        fetching_user: false,
        fetching_user_error: 'This User doesnt exist'
      };
    case CREATING_ACCOUNT:
      return {
        ...state,
        loading: true
      };
    case CREATING_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.responseData.token,
        user: action.responseData.user
      };
    case CREATING_ACCOUNT_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.error_msg
      };
    case UPDATING_USER:
      return {
        ...state,
        isUpdatingUser: true,
        isUpdatingUserSuccess: false
      };
    case UPDATING_USER_SUCCESS:
      var newuser = action.user;
      return {
        ...state,
        isUpdatingUser: false,
        isUpdatingUserSuccess: true,
        user: {
          ...state.user,
          ...newuser
        }
      };
    case UPDATING_USER_FAILED:
      return {
        ...state,
        isUpdatingUser: false,
        isUpdatingUserSuccess: false
      };
    case UPDATING_PASSWORD:
      return {
        ...state,
        isUpdatingPassword: true,
        isUpdatingPasswordSuccess: false
      };
    case UPDATING_PASSWORD_SUCCESS:
      return {
        ...state,
        isUpdatingPassword: false,
        isUpdatingPasswordSuccess: true
      };
    case UPDATING_PASSWORD_FAILED:
      return {
        ...state,
        isUpdatingPassword: false,
        isUpdatingPasswordSuccess: false
      };
    case LOGGING_OUT:
      return {
        ...state
      };
    case LOGGING_OUT_SUCCESS:
      return {
        ...state,
        user: {},
        token: null
      };
    case 'RESET_ERROR_MSGS':
      return {
        ...state,
        error: false,
        error_msg: '',
        isUpdatingUserSuccess: false
      };
    case RESETTING_PASSWORD:
      return {
        ...state,
        resetting: true
      };
    case RESETTING_PASSWORD_SUCCESS:
      return {
        ...state,
        resetting: false,
        reset_msg: action.hide ? '' : action.reset_msg,
        reset_success: action.hide ? false : true
      };
    case RESETTING_PASSWORD_FAILED:
      return {
        ...state,
        resetting: false,
        reset_msg: action.reset_msg,
        reset_success: false
      };
    case VALIDATING_TOKEN:
      return {
        ...state,
        validating: true
      };
    case VALIDATING_TOKEN_SUCCESS:
      return {
        ...state,
        validating: false,
        validx: true,
        validUser: action.validUser
      };
    case VALIDATING_TOKEN_FAILED:
      return {
        ...state,
        validating: false,
        validx: false
      };
    case 'CHANGE_LANG_IN_SETTINGS':
      return {
        ...state,
        user: {
          ...state.user,
          user_preferences: {
            ...state.user.user_preferences,
            locale: {
              ...state.user.user_preferences.locale,
              lang: action.lang
            }
          }
        }
      };
    case 'VERIFYING_ACCOUNT':
      return {
        ...state,
        account_verifying: true
      };
    case 'VERIFYING_ACCOUNT_SUCCESS':
      return {
        ...state,
        account_verifying: false,
        account_verify_status: 'success'
      };
    case 'VERIFYING_ACCOUNT_FAILED':
      return {
        ...state,
        account_verifying: false,
        account_verify_status: 'failed'
      };
    case ADD_ADMIN:
      return {
        ...state
      };
    case ADD_ADMIN_SUCCESS:
      return {
        ...state
      };
    case ADD_ADMIN_FAILED:
      return {
        ...state
      };

    default:
      return state;
  }
};
