//import config from '../../appconfig';

export const API_BAZ =
  (process.env.REACT_APP_API || "").replace("v1", "") ||
  //'http://localhost:1900/api/' ||
  // 'https://insyt-api.esoko.com/api/' ||
  // 'https://gnhr-api.esoko.com/api/' ||
  // 'https://lsr-test-api.esoko.com/api/' ||
  // 'https://lsr-api.esoko.com/api/' ||
  "http://insyt-api.qa.esoko.com:1900/api/" ||
  "https://lsr-api.esoko.com/api/";

export const API_URL = API_BAZ + "v1";

export const API_URL2 = API_BAZ + "v2";

export const SSO_API =
  process.env.REACT_APP_SSO_API_URL ||
  // 'http://192.168.3.190:9077/api/v2' ||
  "http://accounts-sso.qa.esoko.com:5050/api/v2";

export const RESPONSE_TABLE_SIZE = process.env.RESPONSE_TABLE_SIZE || 70;

export const HIGHCHARTS_EXPORT_URL =
  process.env.REACT_APP_HIGHCHARTS_EXPORT_URL ||
  "https://export.highcharts.com/" ||
  "https://insyt.esoko.com/dl/graph" ||
  "https://export.highcharts.com/";

export const INTERCOM_APP_ID =
  process.env.REACT_APP_INTERCOM_APP_ID || "jnpmstartxm2i8o8" || "py1q760t";

export const METRICS_ENDPOINT =
  process.env.REACT_APP_METRICS_ENDPOINT ||
  "https://insyt-metrics-api.esoko.com/web/logs" ||
  "http://192.168.2.137:1901/web/logs";

export const REACT_APP_VERSION = process.env.REACT_APP_VERSION || "0.0.0";
export const SUCCESS = 1;
export const FAIL = 0;
export const SERVER_ERROR = 2;
export const NO_INTERNET_CONNECTION = 3;
export const DELETE_SUCCESS = 4;
export const REACT_APP_COOKIES_DOMAIN =
  process.env.REACT_APP_COOKIES_DOMAIN || "localhost";
export const RELEASE_STAGE =
  process.env.REACT_APP_RELEASE_STAGE || "development";

export const REACT_APP_ANALYTICS_APP_URL =
  process.env.REACT_APP_ANALYTICS_APP_URL || "http://localhost:3001";
export const REACT_APP_ABIS_APP_URL =
  process.env.REACT_APP_ABIS_APP_URL || "http://localhost:3002";

export const WEBSITE_HOME = process.env.REACT_APP_WEBSITE_HOME || "/en/login";
export const STORAGE_PRFX = process.env.REACT_APP_STORAGE_PREFIX || "esk_";
export const EXCEL_DOWNLOAD_OPTIONS =
  process.env.REACT_APP_EXCEL_DOWNLOAD_OPTIONS || "disabled";
export const ACCOUNT_APP_URL = process.env.REACT_APP_ACCOUNT_APP_URL;
export const PRICES_API_URL =
  process.env.REACT_APP_PRICES_API_URL ||
  "http://prices-api.qa2.esoko.com:8085/api/v1";
export const PRICE_FORMS = (
  process.env.REACT_APP_PRICES_FORM_UUIDS || ""
).split(",");
// ::: billing
export const BILLING_UNLIMITED_SLOTS = 10000000;

// ::: album
export const ALBUM_EXTRA_FIELDS_COUNT =
  process.env.REACT_APP_ALBUM_EXTRA_FIELDS_COUNT || 7;

// ::: qa3
export const RESPONSE_FLAGS =
  process.env.REACT_APP_RESTRICT_RESPONSE_FLAGS || "Verified,Draft,Ok,Sweet";
export const RESPONSE_FLAGS_ROLE =
  process.env.REACT_APP_RESTRICT_RESPONSE_FLAGS_ROLE || "dev";
export const RESPONSE_FLAGS_UPDATE =
  process.env.REACT_APP_RESTRICT_RESPONSE_FLAGS_UPDATE || "Great,Sweet";

// ::: predefined comments
export const REJECT_COMMENTS =
  process.env.REACT_APP_REJECT_WITH_CANNED_MESSAGES || "true";

export const dev = window.location.hostname === "localhost" ? true : false;
export const PrimaryColor = "#05548d"
