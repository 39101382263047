import {
  FETCHING_PROJECT,
  FETCHING_PROJECT_SUCCESS,
  FETCHING_PROJECT_FAILED,
  FETCHING_PROJECT_SURVEYS,
  FETCHING_PROJECT_SURVEYS_SUCCESS,
  FETCHING_PROJECT_SURVEYS_FAILED,
  TOGGLE_CREATE_SURVEY_MODAL,
  TOGGLE_ALTERNATE_SIDEBAR,
  TOGGLE_SIDEBAR,
  CREATING_SURVEY,
  CREATING_SURVEY_SUCCESS,
  CREATING_SURVEY_FAILED,
  CLONING_SURVEY,
  CLONING_SURVEY_SUCCESS,
  CLONING_SURVEY_FAILED,
  SET_ACTIVE_SURVEY,
  UNSET_ACTIVE_SURVEY,
  FETCHING_SURVEY,
  FETCHING_SURVEY_SAVING,
  FETCHING_SURVEY_SUCCESS,
  FETCHING_SURVEY_FAILED,
  FETCHING_SURVEY_RESPONSES,
  FETCHING_COLLAB_PROJECTS,
  FETCHING_COLLAB_PROJECTS_SUCCESS,
  FETCHING_COLLAB_PROJECTS_FAILED,
  FETCHING_SURVEY_RESPONSES_SUCCESS,
  FETCHING_SURVEY_RESPONSES_FAILED,
  DELETING_SURVEY_RESPONSES,
  DELETING_SURVEY_RESPONSES_SUCCESS,
  DELETING_SURVEY_RESPONSES_FAILED,
  DELETING_SURVEY,
  DELETING_SURVEY_SUCCESS,
  DELETING_SURVEY_FAILED,
  DOWNLOADING_RESPONSES_IN_EXCEL,
  DOWNLOADING_RESPONSES_IN_EXCEL_FAILED,
  DOWNLOADING_RESPONSES_IN_EXCEL_SUCCESS,
  UPDATING_PROJECT_DETAILS,
  UPDATING_PROJECT_DETAILS_SUCCESS,
  UPDATING_PROJECT_DETAILS_FAILED,
  FETCHING_FORM_REPORT,
  FETCHING_FORM_REPORT_SUCCESS,
  FETCHING_FORM_REPORT_FAILED,
  LOGGING_OUT_SUCCESS,
  FETCHING_DUPLICATE_RESPONDENTS,
  FETCHING_DUPLICATE_RESPONDENTS_SUCCESS,
  FETCHING_DUPLICATE_RESPONDENTS_FAILED,
  UPLOADING_TREE_EXCEL_SHEET,
  UPLOADING_TREE_EXCEL_SHEET_SUCCESS,
  UPLOADING_TREE_EXCEL_SHEET_FAILED,
  SETTING_RESPONSE_FLAG,
  SETTING_RESPONSE_FLAG_FAILED,
  SETTING_RESPONSE_FLAG_SUCCESS,
  FETCHING_SELECTED_FLAG_RESPONSES,
  FETCHING_SELECTED_FLAG_RESPONSES_FAILED,
  FETCHING_SELECTED_FLAG_RESPONSES_SUCCESS,
  SEARCH_FOR_RESPONSE,
  SEARCH_FOR_RESPONSE_SUCCESS,
  SEARCH_FOR_RESPONSE_FAILED,
  REARRANGE_PAGES,
  DOWNLOADING_RESPONSE_IMAGES,
  DOWNLOADING_RESPONSE_IMAGES_FAILED,
  DOWNLOADING_RESPONSE_IMAGES_SUCCESS,
  RE_CALCULATE,
  RE_CALCULATE_FAILED,
  RE_CALCULATE_SUCCESS,
  SAVE_EDITED_RESPONSE,
  SAVE_EDITED_RESPONSE_SUCCESS,
  SAVE_EDITED_RESPONSE_FAILED,
  FETCHING_FILTERED_SURVEY_RESPONSES_FAILED,
  FETCHING_FILTERED_SURVEY_RESPONSES,
  FETCHING_FILTERED_SURVEY_RESPONSES_SUCCESS,
  ASSIGNING_SURVEY_RESPONSES_TO_AGENT,
  ASSIGNING_SURVEY_RESPONSES_TO_AGENT_SUCCESS,
  ASSIGNING_SURVEY_RESPONSES_TO_AGENT_FAILED,
  TOGGLE_CREATE_REVIEW_EXERCISE_MODAL,
  CREATE_REVIEW_EXERCISE,
  CREATE_REVIEW_EXERCISE_SUCCESS,
  CREATE_REVIEW_EXERCISE_FAILED,
  FETCH_REVIEW_EXERCISE,
  FETCH_REVIEW_EXERCISE_SUCCESS,
  FETCH_REVIEW_EXERCISE_FAILED,
  TOGGLE_DOWNLOAD_LATER_MODAL,
  UPDATE_REVIEW_EXERCISE_FAILED,
  UPDATE_REVIEW_EXERCISE_SUCCESS,
  UPDATE_REVIEW_EXERCISE,
  TOGGLE_NOTIFICATIONS_MODAL,
  FETCH_INVITATIONS,
  FETCH_INVITATIONS_SUCCESS,
  FETCH_INVITATIONS_FAILED,
  DECLINE_INVITE,
  DECLINE_INVITE_SUCCESS,
  DECLINE_INVITE_FAILED,
  APPROVE_INVITE,
  APPROVE_INVITE_SUCCESS,
  APPROVE_INVITE_FAILED,
  SEARCH_REVIEW_LIST,
  SEARCH_REVIEW_LIST_SUCCESS,
  SEARCH_REVIEW_LIST_FAILED,
  FETCH_PROJECT_AGENTS,
  FETCH_PROJECT_AGENTS_SUCCESS,
  FETCH_PROJECT_AGENTS_FAILED,
  ASSIGN_LIST_TO_AGENT,
  ASSIGN_LIST_TO_AGENT_SUCCESS,
  ASSIGN_LIST_TO_AGENT_FAILED,
  DOWNLOADING_RESPONSE_SIGNATURES,
  DOWNLOADING_RESPONSE_SIGNATURES_FAILED,
  DOWNLOADING_RESPONSE_SIGNATURES_SUCCESS,
  SAVING_BILLING_INTENT,
  SAVING_BILLING_INTENT_FAILED,
  SAVING_BILLING_INTENT_SUCCESS,
  // POSTING_BILLING_INFO,
  POSTING_BILLING_INFO_SUCCESS
  // POSTING_BILLING_INFO_FAILED
} from '../../utils/types';

const initialState = {
  survey_responses: [],
  recalculating: false,
  showAppInfoCard: false,
  AppInfoCard: '',
  sId: null,
  response_meta: {},
  pageNumber: 0,
  total: 0,
  survey_response_count: 0,
  fetching_responses: true,
  active_survey_id: {},
  alt_sidebar_open: false,
  sidebar_open: false,
  create_survey_open: false,
  donwloading_res_signatures: false,
  creating: false,
  create_survey_error: false,
  create_survey_error_msg: '',
  loading: true,
  loading_surveys: true,
  project: {},
  userProject: {},
  loading_user_project: true,
  surveys: [],
  survey_meta: null,
  error: false,
  surveys_error: false,
  error_msg: '',
  surveys_error_msg: '',
  fetching_active_survey: true,
  fetching_active_survey_saving: false,
  active_survey: {},
  deleting_responses: false,
  deleting_survey: false,
  delete_success: null,
  downloading_excel: false,
  downloading_success: false,
  updating_project_details: false,
  cloning: false,
  showVerifyModal: false,
  form_report: [],
  fetching_report: true,
  collab_projects: [],
  loading_collab_projects: false,
  fetching_duplicate_respondents: false,
  showingDuplicates: false,
  treeData: null,
  uploadedTree: null,
  fetching_tree_data: false,
  dataUploaded: false,
  searching: false,
  active_survey_page: undefined,
  saving_edited_response: false,
  editSaveFailed: false,
  create_review_exercise_open: false,
  creating_review_exercise: false,
  updating_review_exercise: false,
  fetching_review_exercise: false,
  searching_review_exercise_list: false,
  searched_review_exercise_list: false,
  review_exercise: {
    reviewList: [],
    reviewMeta: {},
    segments: []
  },
  show_download_later_modal: false,
  added_review: {},
  show_notifications_modal: false,
  invites: [],
  fetching_invites: false,
  declining_invite: false,
  approving_invite: false,
  fetching_review_list_summary: false,
  review_summary: {},
  fetching_project_agents: false,
  review_agents: [],
  assigning_list_agent_agents: false,
  billing_intent: false,
  form_settings_saving: false
};

const sysFlags = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGGING_OUT_SUCCESS:
      return {
        ...state,
        project: {},
        surveys: [],
        form_report: [],
        active_survey: {},
        survey_responses: [],
        response_meta: {},
        collab_projects: [],
        review_exercises: [],
        invites: []
      };
    case 'UPDATING_PAGE_NAME':
      return {
        ...state,
        active_survey: {
          ...state.active_survey,
          flags: [...sysFlags, ...state.active_survey.flags],
          draft_data: {
            pages: action.pages
          }
        }
      };

    case 'SET_ACTIVE_FORM_PAGE':
      return {
        ...state,
        active_survey_page: action.page
      };

    case DOWNLOADING_RESPONSE_IMAGES:
      return {
        ...state,
        showAppInfoCard: true,
        AppInfoCard: 'Zipping images'
      };

    case DOWNLOADING_RESPONSE_IMAGES_SUCCESS:
      return {
        ...state,
        showAppInfoCard: false,
        AppInfoCard: '',
        show_download_later_modal: action.download_later
          ? action.download_later
          : false
      };

    case DOWNLOADING_RESPONSE_IMAGES_FAILED:
      return {
        ...state,
        showAppInfoCard: false,
        AppInfoCard: ''
      };

    case TOGGLE_CREATE_SURVEY_MODAL:
      return {
        ...state,
        create_survey_open: !state.create_survey_open
      };
    case TOGGLE_DOWNLOAD_LATER_MODAL:
      return {
        ...state,
        show_download_later_modal: !state.show_download_later_modal
      };
    case TOGGLE_NOTIFICATIONS_MODAL:
      return {
        ...state,
        show_notifications_modal: !state.show_notifications_modal
      };

    case TOGGLE_CREATE_REVIEW_EXERCISE_MODAL:
      return {
        ...state,
        create_review_exercise_open: !state.create_review_exercise_open
      };

    case TOGGLE_ALTERNATE_SIDEBAR:
      return {
        ...state,
        alt_sidebar_open: !state.alt_sidebar_open,
        sidebar_open: false
      };
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebar_open: !state.sidebar_open
      };
    case CREATING_SURVEY:
      return {
        ...state,
        creating: true
      };
    case CREATING_SURVEY_SUCCESS:
      return {
        ...state,
        creating: false
        // project: action.project
      };
    case CREATING_SURVEY_FAILED:
      return {
        ...state,
        creating: false,
        create_survey_error: true,
        create_survey_error_msg: action.create_survey_error_msg
      };
    case CLONING_SURVEY:
      return {
        ...state,
        cloning: true
      };
    case CLONING_SURVEY_SUCCESS:
      return {
        ...state,
        cloning: false,
        surveys: [...state.surveys, action.form]
      };
    case CLONING_SURVEY_FAILED:
      return {
        ...state,
        cloning: false
      };
    case DELETING_SURVEY:
      return {
        ...state,
        deleting_survey: true
      };
    case DELETING_SURVEY_SUCCESS:
      return {
        ...state,
        deleting_survey: false
      };

    case DELETING_SURVEY_FAILED:
      return {
        ...state,
        deleting_survey: false
      };
    case FETCHING_PROJECT:
      return {
        ...state,
        loading: true
      };
    case FETCHING_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        project: action.project
      };
    case FETCHING_PROJECT_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.error_msg
      };
    case 'FETCHING_USER_PROJECT':
      return {
        ...state,
        loading_user_project: true
      };
    case 'FETCHING_USER_PROJECT_SUCCESS':
      return {
        ...state,
        loading_user_project: false,
        userProject: action.userProject
      };
    case 'FETCHING_USER_PROJECT_FAILED':
      return {
        ...state,
        loading_user_project: false
      };
    case FETCHING_PROJECT_SURVEYS:
      return {
        ...state,
        loading_surveys: true
      };
    case FETCHING_PROJECT_SURVEYS_SUCCESS:
      return {
        ...state,
        loading_surveys: false,
        surveys: action.surveys
      };
    case FETCHING_PROJECT_SURVEYS_FAILED:
      return {
        ...state,
        loading_surveys: false,
        surveys_error: true,
        surveys_error_msg: action.surveys_error_msg
      };

    case SET_ACTIVE_SURVEY:
      const aSurvey = state.surveys.filter(survey => survey.id === action.id);

      return {
        ...state,
        active_survey_id: { id: action.id, status: action.status },
        active_survey: { ...aSurvey[0], flags: [...sysFlags, ...aSurvey[0].flags], }
      };
    case UNSET_ACTIVE_SURVEY:
      return {
        ...state,
        active_survey: {}
      };
    case FETCHING_SURVEY:
      return {
        ...state,
        fetching_active_survey: true
      };
    case FETCHING_SURVEY_SAVING:
      return {
        ...state,
        fetching_active_survey_saving: true
      };
    case FETCHING_SURVEY_SUCCESS:
      return {
        ...state,
        fetching_active_survey: false,
        fetching_active_survey_saving: false,
        active_survey: { ...action.data, flags: [...sysFlags, ...action.data.flags], },
        active_survey_page: action.data.draft_data || {
          questions: []
        },
        survey_responses: [],
        form_report: [],
        total_responses: action.data.result_count,
        uploadedTree: null
      };
    case FETCHING_SURVEY_FAILED:
      return {
        ...state,
        fetching_active_survey: false,
        fetching_active_survey_saving: false
      };
    case 'UPDATE_SURVEY_LIST':
      if (action.actionType === 'update') {
        let newSurveys = state.surveys.map(survey =>
          survey.id === action.survey.id ? action.survey : survey
        );

        return {
          ...state,
          surveys: [...newSurveys],
          active_survey: { ...action.survey, flags: [...sysFlags, ...action.survey.flags], }
        };
      } else {
        let newSurveys = state.surveys.filter(
          survey => survey.id !== action.survey.id
        );

        return {
          ...state,
          surveys: [...newSurveys]
        };
      }
    case FETCHING_FILTERED_SURVEY_RESPONSES:
      return {
        ...state,
        searching: true
      };

    case FETCHING_FILTERED_SURVEY_RESPONSES_SUCCESS:
      return {
        ...state,
        survey_responses: action.data,
        pageNumber: action.pageNumber,
        total: action.total,
        fetching_responses: false,
        searching: false,
        response_meta: action.meta,
        showingDuplicates: false,
        sId: action.sId,
        active_survey: {
          ...state.active_survey,
          response_count: action.total,
          flags: [...action.meta.flags, ...sysFlags]
        },
        surveys: state.surveys.map(survey =>
          survey.id === state.active_survey.id
            ? { ...survey, response_count: action.total }
            : survey
        )
      };

    case FETCHING_SURVEY_RESPONSES:
      return {
        ...state,
        searching: true,
        fetching_responses: true
      };

    case FETCHING_SURVEY_RESPONSES_SUCCESS:
      return {
        ...state,
        survey_responses:
          action.pageNumber > 1
            ? [...state.survey_responses, ...action.data]
            : action.data,
        pageNumber: action.pageNumber,
        total: action.total,
        fetching_responses: false,
        searching: false,
        response_meta: action.meta,
        showingDuplicates: false,
        sId: action.sId,
        active_survey: {
          ...state.active_survey,
          response_count: action.total,
          flags: [...action.meta.flags, ...sysFlags]
        },
        surveys: state.surveys.map(survey =>
          survey.id === state.active_survey.id
            ? { ...survey, response_count: action.total }
            : survey
        )
      };
    case FETCHING_SURVEY_RESPONSES_FAILED:
      return {
        ...state,
        searching: false,
        fetching_responses: false
      };
    case FETCHING_FILTERED_SURVEY_RESPONSES_FAILED:
      return {
        ...state,
        survey_responses: [],
        searching: false
      };
    case 'POSTING_FORM_ERRORS_SUCCESS':
      return {
        ...state,
        survey_responses: state.survey_responses.map(response =>
          response.id === action.responseId ? action.data.responses : response
        )
      };

    case DELETING_SURVEY_RESPONSES:
      return {
        ...state,
        deleting_responses: true
      };
    case DELETING_SURVEY_RESPONSES_SUCCESS:
      return {
        ...state,
        deleting_responses: false,
        delete_success: true,
        survey_responses: state.survey_responses.filter(s =>
          action.deletedIds.indexOf(s.id)
        )
      };
    case DELETING_SURVEY_RESPONSES_FAILED:
      return {
        ...state,
        deleting_responses: false,
        assign_success: false
      };
    case ASSIGNING_SURVEY_RESPONSES_TO_AGENT:
      return {
        ...state,
        assigning_responses_to_agent: true
      };
    case ASSIGNING_SURVEY_RESPONSES_TO_AGENT_SUCCESS:
      return {
        ...state,
        assigning_responses_to_agent: false,
        assign_success: true
        // survey_responses: state.survey_responses.filter(s =>
        //   action.assignedIds.indexOf(s.id)
        // )
      };
    case ASSIGNING_SURVEY_RESPONSES_TO_AGENT_FAILED:
      return {
        ...state,
        assigning_responses_to_agent: false,
        assign_success: false
      };
    case 'RESET_SURVEY_RESPONSES':
      return {
        ...state,
        survey_responses: []
      };
    case DOWNLOADING_RESPONSE_SIGNATURES:
      return {
        ...state,
        donwloading_res_signatures: true,
        showAppInfoCard: true,
        AppInfoCard: 'Downloading response signatures'
      };

    case DOWNLOADING_RESPONSE_SIGNATURES_FAILED:
      return {
        ...state,
        donwloading_res_signatures: false,
        showAppInfoCard: false,
        AppInfoCard: ''
      };

    case DOWNLOADING_RESPONSE_SIGNATURES_SUCCESS:
      return {
        ...state,
        donwloading_res_signatures: false,
        showAppInfoCard: false,
        AppInfoCard: ''
      };
    case DOWNLOADING_RESPONSES_IN_EXCEL:
      return {
        ...state,
        downloading_excel: true,
        showAppInfoCard: true,
        AppInfoCard: 'Downloading excel sheet'
      };
    case DOWNLOADING_RESPONSES_IN_EXCEL_SUCCESS:
      return {
        ...state,
        downloading_excel: false,
        downloading_success: true,
        showAppInfoCard: false,
        AppInfoCard: '',
        show_download_later_modal: action.download_later ? true : false
      };
    case DOWNLOADING_RESPONSES_IN_EXCEL_FAILED:
      return {
        ...state,
        downloading_excel: false,
        downloading_success: false,
        showAppInfoCard: false,
        AppInfoCard: ''
      };
    case UPDATING_PROJECT_DETAILS:
      return { ...state, updating_project_details: true };
    case UPDATING_PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        project: action.project,
        updating_project_details: false
      };
    case UPDATING_PROJECT_DETAILS_FAILED:
      return { ...state, updating_project_details: false };

    case 'FORM_SETTING':
      return {
        ...state,
        form_settings_saving: true
      };
    case 'FORM_SETTING_SUCCESS':
      return {
        ...state,
        form_settings_saving: false,
        active_survey: {
          ...state.active_survey,
          form_preferences: {
            ...action.settings.preferences
          },
          flags: [...sysFlags, ...state.active_survey.flags],
        }
      };
    case 'FORM_SETTING_FAILED':
      return {
        ...state,
        form_settings_saving: false
      };
    case 'SHOW_VERIFY_MODAL':
      return {
        ...state,
        showVerifyModal: true
      };
    case 'HIDE_VERIFY_MODAL':
      return {
        ...state,
        showVerifyModal: false
      };
    case FETCHING_FORM_REPORT:
      return {
        ...state,
        fetching_report: true
      };
    case FETCHING_FORM_REPORT_SUCCESS:
      return {
        ...state,
        fetching_report: false,
        form_report: action.report.filter(
          report =>
            report.type !== 'section' &&
            report.type !== 'section-break' &&
            report.type !== 'intro-text' &&
            report.type !== '' &&
            report.type !== undefined
        )
      };
    case FETCHING_FORM_REPORT_FAILED:
      return {
        ...state,
        fetching_report: false
      };
    case FETCHING_COLLAB_PROJECTS:
      return {
        ...state,
        loading_collab_projects: true
      };
    case FETCHING_COLLAB_PROJECTS_SUCCESS:
      return {
        ...state,
        loading_collab_projects: false,
        collab_projects: action.projects
      };
    case FETCHING_COLLAB_PROJECTS_FAILED:
      return { ...state, loading_collab_projects: false };
    case FETCHING_DUPLICATE_RESPONDENTS:
      return {
        ...state,
        fetching_duplicate_respondents: true
      };
    case FETCHING_DUPLICATE_RESPONDENTS_SUCCESS:
      return {
        ...state,
        fetching_duplicate_respondents: false,
        showingDuplicates: true,
        survey_responses: [...action.data]
      };
    case FETCHING_DUPLICATE_RESPONDENTS_FAILED:
      return {
        ...state,
        fetching_duplicate_respondents: false
      };
    case UPLOADING_TREE_EXCEL_SHEET:
      return {
        ...state,
        fetching_tree_data: true
      };
    case UPLOADING_TREE_EXCEL_SHEET_SUCCESS:
      return {
        ...state,
        fetching_tree_data: false,
        dataUploaded: action.hide ? false : true,
        active_survey: {
          ...state.active_survey,
          draft_data: {
            pages: [
              ...action.pages.map((page, index) =>
                (page.type === 'sub-form' ||
                  page.questions[0].type === 'sub-form') &&
                  action.question &&
                  index === action.activePage
                  ? {
                    ...page,
                    questions: [
                      {
                        ...page.questions[0],
                        subform: {
                          ...page.questions[0].subform,
                          active_data: {
                            pages: [
                              {
                                ...page.questions[0].subform.active_data
                                  .pages[0],
                                questions: [
                                  ...page.questions[0].subform.active_data.pages[0].questions.map(
                                    que =>
                                      que.id !== action.qId
                                        ? que
                                        : {
                                          ...action.question,
                                          tree: action.data,
                                          tree_headings: action.tree_headings.map(
                                            (h, i) => ({
                                              id: `${action.qId}-${i}`,
                                              title: h
                                            })
                                          )
                                        }
                                  )
                                ]
                              }
                            ]
                          }
                        }
                      }
                    ]
                  }
                  : {
                    ...page,
                    questions: page.questions.map(question =>
                      question.id === action.qId
                        ? {
                          ...question,
                          tree: action.data,
                          tree_headings: action.tree_headings.map(
                            (h, i) => ({
                              id: `${action.qId}-${i}`,
                              title: h
                            })
                          )
                        }
                        : question
                    )
                  }
              )
            ]
          }
        }
      };
    case UPLOADING_TREE_EXCEL_SHEET_FAILED:
      return {
        ...state,
        fetching_tree_data: false,
        treeData: [],
        uploadedTree: null
      };

    case SETTING_RESPONSE_FLAG:
      return {
        ...state,
        settings_res_flag: true
      };

    case SETTING_RESPONSE_FLAG_SUCCESS:
      return {
        ...state,
        survey_responses: state.survey_responses.map(response =>
          response.id === action.data.id ? action.data : response
        ),
        settings_res_flag: false

        // active_survey: {
        //   ...state.active_survey,
        //   flags: action.flags
        // }
      };
    case SETTING_RESPONSE_FLAG_FAILED:
      return {
        ...state,
        settings_res_flag: false
      };

    case FETCHING_SELECTED_FLAG_RESPONSES:
      return {
        ...state,
        fetching_responses: true
      };

    case FETCHING_SELECTED_FLAG_RESPONSES_SUCCESS:
      return {
        ...state,
        survey_responses:
          action.pageNumber > 1
            ? [...state.survey_responses, ...action.data]
            : action.data,
        fetching_responses: false
      };
    case FETCHING_SELECTED_FLAG_RESPONSES_FAILED:
      return {
        ...state,
        fetching_responses: false
      };
    case SEARCH_FOR_RESPONSE:
      return {
        ...state,
        searching: true
      };

    case SEARCH_FOR_RESPONSE_SUCCESS:
      return {
        ...state,
        searching: false,
        survey_responses:
          action.pageNumber > 1
            ? [...state.survey_responses, ...action.data]
            : action.data,
        pageNumber: action.pageNumber,
        total: action.total,
        response_meta: action.meta,
        showingDuplicates: false,
        sId: action.sId,
        active_survey: {
          ...state.active_survey,
          response_count: action.total,
          flags: [...action.meta.flags, ...sysFlags]
        }

        // surveys: state.surveys.map(survey =>
        //   survey.id === state.active_survey.id
        //     ? { ...survey, response_count: action.total }
        //     : survey
        // )
      };
    case SEARCH_FOR_RESPONSE_FAILED:
      return {
        ...state,
        searching: false
      };
    case REARRANGE_PAGES:
      return {
        ...state,
        active_survey: {
          ...state.active_survey,
          draft_data: {
            ...state.active_survey.draft_data,
            pages: action.pages
          },
          flags: [...sysFlags, ...state.active_survey.flags],
        }
      };

    case RE_CALCULATE:
      return {
        ...state,
        fetching_responses: true
      };

    case RE_CALCULATE_FAILED:
      return {
        ...state,
        fetching_responses: false
      };

    case RE_CALCULATE_SUCCESS:
      return {
        ...state,
        fetching_responses: false
      };
    case SAVE_EDITED_RESPONSE:
      return {
        ...state,
        saving_edited_response: true
      };
    case SAVE_EDITED_RESPONSE_SUCCESS:
      let items = state.survey_responses;
      let foundIndex = items.findIndex(
        x => x.response_uuid === action.data.response_uuid
      );
      items[foundIndex] = action.data;

      return {
        ...state,
        saving_edited_response: false,
        survey_responses: items,
        editSaveFailed: action.editSaveFailed
      };
    case SAVE_EDITED_RESPONSE_FAILED:
      return {
        ...state,
        saving_edited_response: false,
        editSaveFailed: action.editSaveFailed
      };

    case SEARCH_REVIEW_LIST:
      return {
        ...state,
        searching_review_exercise_list: true
      };

    case SEARCH_REVIEW_LIST_SUCCESS:
      return {
        ...state,
        searching_review_exercise_list: false,
        searched_review_exercise_list: true,
        review_exercise: {
          reviewList: action.payload['data']
        }
      };

    case SEARCH_REVIEW_LIST_FAILED:
      return {
        ...state,
        searching_review_exercise_list: false
      };

    case CREATE_REVIEW_EXERCISE:
      return {
        ...state,
        creating_review_exercise: true
      };

    case CREATE_REVIEW_EXERCISE_SUCCESS:
      return {
        ...state,
        creating_review_exercise: false,
        added_review: action.payload['data']
      };

    case CREATE_REVIEW_EXERCISE_FAILED:
      return {
        ...state,
        creating_review_exercise: false
      };

    case UPDATE_REVIEW_EXERCISE:
      return {
        ...state,
        updating_review_exercise: true
      };

    case UPDATE_REVIEW_EXERCISE_SUCCESS:
      return {
        ...state,
        updating_review_exercise: false
      };

    case UPDATE_REVIEW_EXERCISE_FAILED:
      return {
        ...state,
        updating_review_exercise: false
      };
    case FETCH_REVIEW_EXERCISE:
      return {
        ...state,
        fetching_review_exercise: true
      };

    case FETCH_REVIEW_EXERCISE_SUCCESS:
      return {
        ...state,
        fetching_review_exercise: false,
        review_exercise: {
          reviewList: action.payload['data'],
          reviewMeta: action.payload['meta']
        }
      };

    case FETCH_REVIEW_EXERCISE_FAILED:
      return {
        ...state,
        fetching_review_exercise: false
      };

    case FETCH_INVITATIONS:
      return {
        ...state,
        fetching_invites: true
      };

    case FETCH_INVITATIONS_SUCCESS:
      return {
        ...state,
        fetching_invites: false,
        invites: action.payload['data']
      };

    case FETCH_INVITATIONS_FAILED:
      return {
        ...state,
        fetching_invites: false
      };
    case DECLINE_INVITE:
      return {
        ...state,
        declining_invite: true
      };

    case DECLINE_INVITE_SUCCESS:
      return {
        ...state,
        declining_invite: false
      };

    case DECLINE_INVITE_FAILED:
      return {
        ...state,
        declining_invite: false
      };
    case APPROVE_INVITE:
      return {
        ...state,
        approving_invite: true
      };

    case APPROVE_INVITE_SUCCESS:
      return {
        ...state,
        approving_invite: false
      };

    case APPROVE_INVITE_FAILED:
      return {
        ...state,
        approving_invite: false
      };
    case FETCH_PROJECT_AGENTS:
      return {
        ...state,
        fetching_project_agents: true
      };

    case FETCH_PROJECT_AGENTS_SUCCESS:
      return {
        ...state,
        fetching_project_agents: false,
        review_agents: action.payload['data']
      };

    case FETCH_PROJECT_AGENTS_FAILED:
      return {
        ...state,
        fetching_project_agents: false
      };
    case ASSIGN_LIST_TO_AGENT:
      return {
        ...state,
        assigning_list_agent_agents: true
      };

    case ASSIGN_LIST_TO_AGENT_SUCCESS:
      return {
        ...state,
        assigning_list_agent_agents: false
      };

    case ASSIGN_LIST_TO_AGENT_FAILED:
      return {
        ...state,
        assigning_list_agent_agents: false
      };
    case SAVING_BILLING_INTENT:
      return {
        ...state,
        billing_intent: true,
        billing_error: null
      };
    case SAVING_BILLING_INTENT_SUCCESS:
      return {
        ...state,
        billing_intent: false
      };
    case SAVING_BILLING_INTENT_FAILED:
      return {
        ...state,
        billing_intent: false,
        billing_error: action.error
      };
    // case POSTING_BILLING_INFO:
    //   return {
    //     ...state
    //   };
    case POSTING_BILLING_INFO_SUCCESS:
      return {
        ...state,
        project: action.account,
        billing_intent: false
      };
    // case POSTING_BILLING_INFO_FAILED:
    //   return {
    //     ...state
    //   };
    default:
      return state;
  }
};
