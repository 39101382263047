import React from 'react';
import { Modal, Button, Icon } from 'antd';
import { connect } from 'react-redux';
import { hideModal, showModal } from '../actions/utils';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const UnpaidBill = props => {
  return (
    <Modal
      destroyOnClose
      centered
      visible={props.modalShow}
      maskStyle={{
        background: 'rgba(0, 0, 0, 0.90)'
      }}
      bodyStyle={{ padding: 0 }}
      onCancel={() => {
        props.hideModal();
        props.redirectOnClose &&
          props.history.replace(
            `/${props.lang}/forms/${props.activeSurvey.id}`
          );
      }}
      title="Update billing information"
      footer={[
        <span
          key="back"
          style={{ color: '#0628A5', cursor: 'pointer', marginRight: '1rem' }}
          onClick={() => {
            props.hideModal();
            props.redirectOnClose &&
              props.history.replace(
                `/${props.lang}/forms/${props.activeSurvey.id}`
              );
          }}
        >
          <FormattedMessage id="Button.Dismiss" defaultMessage="Dismiss" />
        </span>,
        <Button
          key="fix"
          type="primary"
          onClick={() =>
            props.showModal('PAYMENT_INFO', {
              projectUuid: props.project.uuid,
              userUuid: props.user.user_id,
              currentUrl: window.location.href
            })
          }
        >
          <FormattedMessage id="Button.FixNow" defaultMessage="Fix now" />
        </Button>
      ]}
    >
      <div>
        <div
          style={{
            marginTop: '1rem',
            backgroundColor: '#D7373F',
            color: 'white',
            padding: '1rem 0'
          }}
        >
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Icon
              type="warning"
              theme="filled"
              style={{ marginRight: '.5rem', fontSize: '24px' }}
            />{' '}
            <FormattedMessage id="App.Text.CardDeclined" defaultMessage="There was a problem billing your card" />
          </p>
        </div>
        <div style={{ margin: '2rem 0', padding: '0 24px' }}>
          <p>
            <FormattedMessage id="Billing.PaymentFailed" defaultMessage="There was a problem with your payment. Click 'Fix now' to update your payment information and restore access to all features on Insyt." />
          </p>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    project: state.dashboard.project,
    lang: state.intl.lang,
    activeSurvey: state.dashboard.active_survey
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hideModal: () => {
      dispatch(hideModal());
    },
    showModal: (modalName, modalProps) => {
      dispatch(showModal(modalName, modalProps));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UnpaidBill)
);
