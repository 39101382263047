import React from 'react';
import { Button, Modal } from 'antd';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { hideModal, showModal } from '../actions/utils';

const CardAttachWarning = props => {
  return (
    <Modal
      centered
      title={
        <p style={{ fontWeight: 'bold' }}>
          {' '}
          <FormattedMessage
            defaultMessage="Adding a card"
            id="App.ModalTitle.AddingCard"
          />
        </p>
      }
      destroyOnClose
      visible={props.modalShow}
      onCancel={() => props.hideModal()}
      footer={[
        <Button key="close" type="link" onClick={() => props.hideModal()}>
          <FormattedMessage defaultMessage="Close" id="App.Text.Close" />
        </Button>,
        <Button
          key="continue"
          type="primary"
          onClick={() =>
            props.showModal('PAYMENT_INFO', {
              projectUuid: props.projectUuid,
              userUuid: props.userUuid,
              currentUrl: props.currentUrl
            })
          }
        >
          <FormattedMessage defaultMessage="Continue" id="App.Text.Continue" />
        </Button>
      ]}
    >
      <div>
        <p>
          <FormattedMessage
            defaultMessage="You are a collaborator on the project {projectName}. If you choose to continue, your card will be billed for this project."
            id="App.CardWarningModal.Info"
            values={{ projectName: <b>{props.projectName}</b> }}
          />
        </p>
      </div>
    </Modal>
  );
};

const mapDispatch = dispatch => {
  return {
    hideModal: () => {
      dispatch(hideModal());
    },
    showModal: (modalName, modalProps) => {
      dispatch(showModal(modalName, modalProps));
    }
  };
};

export default connect(
  null,
  mapDispatch
)(CardAttachWarning);
