import {
  FETCHING_AGENTS_LOCATION,
  FETCHING_AGENTS_LOCATION_SUCCESS,
  FETCHING_AGENTS_LOCATION_FAILED,
  FETCHING_RESPONSES_LOCATION,
  FETCHING_RESPONSES_LOCATION_SUCCESS,
  FETCHING_RESPONSES_LOCATION_FAILED,
  LOGGING_OUT_SUCCESS,
  FETCHING_CHART_DATA,
  FETCHING_CHART_DATA_SUCCESS,
  FETCHING_CHART_DATA_FAILED,
  FETCHING_DESC_STATS,
  FETCHING_DESC_STATS_SUCCESS,
  FETCHING_DESC_STATS_FAILED,
  FETCHING_AGENTS_STATS,
  FETCHING_AGENTS_STATS_SUCCESS,
  FETCHING_AGENTS_STATS_FAILED,
  UNSET_ACTIVE_SURVEY,
  POSTING_FORM_ERRORS,
  POSTING_FORM_ERRORS_SUCCESS,
  POSTING_FORM_ERRORS_FAILED,
  FETCHING_FORM_ERRORS,
  FETCHING_FORM_ERRORS_SUCCESS,
  FETCHING_FORM_ERRORS_FAILED,
  SAVING_FAVOURITE_CHART,
  SAVING_FAVOURITE_CHART_SUCCESS,
  SAVING_FAVOURITE_CHART_FAILED,
  LOADING_FAVOURITE_CHART,
  LOADING_FAVOURITE_CHART_SUCCESS,
  LOADING_FAVOURITE_CHART_FAILED,
  FETCHING_RESPONSE_COMMENTS,
  FETCHING_RESPONSE_COMMENTS_FAILED,
  FETCHING_RESPONSE_COMMENTS_SUCCESS,
  SETTING_RESPONSE_FLAG_SUCCESS,
  FETCHING_RESPONSE_AUDITS,
  FETCHING_RESPONSE_AUDITS_FAILED,
  FETCHING_RESPONSE_AUDITS_SUCCESS,
  DOWNLOADING_RESPONSE_IMAGES,
  DOWNLOADING_RESPONSE_IMAGES_FAILED,
  DOWNLOADING_RESPONSE_IMAGES_SUCCESS,
  RESTRICT_AGENT_ON_FORM,
  RESTRICT_AGENT_ON_FORM_SUCCESS,
  RESTRICT_AGENT_ON_FORM_FAILED,
  FILTER_AGENTS_STATS,
  FILTER_SURVEY_QUESTIONS,
  FETCHING_QUESTIONS_AUDIT_TRAIL_SUCCESS,
  FETCHING_QUESTIONS_AUDIT_TRAIL_FAILED,
  FETCHING_QUESTIONS_AUDIT_TRAIL,
  FETCHING_SURVEY,
  SET_ADVANCED_SEARCH_VALUE,
  DELETE_ADVANCED_SEARCH_ITEM_VALUE,
  SAVE_FORM_GEOFENCE,
  SAVE_FORM_GEOFENCE_FAILED,
  SAVE_FORM_GEOFENCE_SUCCESS,
  GET_FORM_GEOFENCES,
  GET_FORM_GEOFENCES_SUCCESS,
  GET_FORM_GEOFENCES_FAILED,
  DELETE_FORM_GEOFENCE,
  DELETE_FORM_GEOFENCE_FAILED,
  DELETE_FORM_GEOFENCE_SUCCESS,
  UPDATE_FORM_GEOFENCE,
  UPDATE_FORM_GEOFENCE_FAILED,
  UPDATE_FORM_GEOFENCE_SUCCESS,
  TOGGLE_DOWNLOAD_LATER_MODAL,
  FETCH_FORM_TEMPLATES ,  UPDATE_FORM_TEMPLATES , FETCH_FORM_TEMPLATES_SUCCESS , FETCH_FORM_TEMPLATES_ERROR  ,
} from '../../utils/types';

const initialState = {
  donwloading_res_images: false,
  agents_location: [],
  agents_location_fetching: true,
  responses_location: [],
  responses_location_fetching: true,
  proportion_data: [],
  proportion_data_fetching: true,
  desc_stats: {},
  agents_stats: [],
  agentAverage: 0,
  agents_stats_fetching: false,
  loading_errors: false,
  form_errors: [],
  favourite_charts: null,
  favourite_chart_loading: false,
  saving_flags: false,
  form_flags: {},
  formTemplates : [] ,
  fetchingFormTemplates :false ,
  fetching_comments: true,
  comments: [],
  fetching_audits: true,
  audits: [],
  restrictingOnForm: false,
  questionAudits: [],
  fetching_questionAudits: false,
  savingGeofence: false,
  fetchingGeofence: false,
  activeGeofence: {},
  geofences: [],
  advancedSearchValues: {
    keys: [],
    values: []
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGGING_OUT_SUCCESS:
      return {
        ...state,
        agents_location: []
      };
    case UNSET_ACTIVE_SURVEY:
      return {
        ...state,
        agents_location: [],
        formTemplates : [] ,
        proportion_data: [],
        desc_stats: {},
        agents_stats: []
      };
    case RESTRICT_AGENT_ON_FORM:
      return {
        ...state,
        restrictingOnForm: true
      };

    case RESTRICT_AGENT_ON_FORM_SUCCESS:
      return {
        ...state,
        restrictingOnForm: false,
        agents_stats: [
          ...state.agents_stats.map(agent =>
            agent.id === action.data.agent_id
              ? { ...agent, agentFormStatus: action.data.status }
              : agent
          )
        ]
      };

    case RESTRICT_AGENT_ON_FORM_FAILED:
      return {
        ...state,
        restrictingOnForm: false
      };
    case FETCHING_AGENTS_LOCATION:
      return {
        ...state,
        agents_location_fetching: true
      };
    case FETCHING_AGENTS_LOCATION_SUCCESS:
      return {
        ...state,
        agents_location_fetching: false,
        agents_location: action.data
      };
    case FETCHING_AGENTS_LOCATION_FAILED:
      return {
        ...state,
        agents_location: [],
        agents_location_fetching: false
      };
    case FETCHING_RESPONSES_LOCATION:
      return {
        ...state,
        responses_location_fetching: true
      };
    case FETCHING_RESPONSES_LOCATION_SUCCESS:
      return {
        ...state,
        responses_location_fetching: false,
        responses_location: action.data
      };
    case FETCHING_RESPONSES_LOCATION_FAILED:
      return {
        ...state,
        responses_location: [],
        responses_location_fetching: false
      };
    case FETCHING_CHART_DATA:
      return {
        ...state,
        proportion_data_fetching: true
      };
    case FETCHING_CHART_DATA_SUCCESS:
      return {
        ...state,
        proportion_data: action.data,
        proportion_data_fetching: false
      };
    case FETCHING_CHART_DATA_FAILED:
      return {
        ...state,
        proportion_data_fetching: false,
        proportion_data: []
      };
    case FETCHING_DESC_STATS:
      return {
        ...state,
        proportion_data_fetching: true
      };
    case FETCHING_DESC_STATS_SUCCESS:
      return {
        ...state,
        desc_stats: action.data,
        proportion_data_fetching: false
      };
    case FETCHING_DESC_STATS_FAILED:
      return {
        ...state,
        proportion_data_fetching: false,
        desc_stats: {}
      };
    case FETCHING_AGENTS_STATS:
      return {
        ...state,
        agents_stats_fetching: true
      };
    case FETCHING_AGENTS_STATS_SUCCESS:
      return {
        ...state,
        agents_stats_fetching: false,
        agents_stats: action.data,
        agentAverage: action.average
      };
    case FETCHING_AGENTS_STATS_FAILED:
      return {
        ...state,
        agents_stats_fetching: false,
        agents_stats: [],
        agentAverage: 0
      };
    case POSTING_FORM_ERRORS:
      return {
        ...state,
        loading_errors: true
      };
    case POSTING_FORM_ERRORS_SUCCESS:
      return {
        ...state,
        loading_errors: false,
        form_errors: state.form_errors.filter(
          form_error => form_error.id === action.responseId
        )
        // action.data.map(d => d.error_details)
      };
    case POSTING_FORM_ERRORS_FAILED:
      return {
        ...state,
        loading_errors: false,
        form_errors: []
      };
    case FETCHING_FORM_ERRORS:
      return {
        ...state,
        loading_errors: true
      };
    case FETCHING_FORM_ERRORS_SUCCESS:
      return {
        ...state,
        loading_errors: false,
        form_errors: action.data.map(d => d.error_details)
      };
    case FETCHING_FORM_ERRORS_FAILED:
      return {
        ...state,
        loading_errors: false,
        form_errors: []
      };
    case SAVING_FAVOURITE_CHART:
      return {
        ...state,
        favourite_chart_loading: true
      };
    case SAVING_FAVOURITE_CHART_SUCCESS:
      return {
        ...state,
        favourite_chart_loading: false,
        favourite_charts: action.data
      };
    case SAVING_FAVOURITE_CHART_FAILED:
      return {
        ...state,
        favourite_chart_loading: false,
        favourite_charts: null
      };

    case LOADING_FAVOURITE_CHART:
      return {
        ...state,
        favourite_chart_loading: true,
        favourite_charts: action.data
      };

    case LOADING_FAVOURITE_CHART_SUCCESS:
      return {
        ...state,
        favourite_chart_loading: false,
        favourite_charts: action.data
      };
    case LOADING_FAVOURITE_CHART_FAILED:
      return {
        ...state,
        favourite_chart_loading: false,
        favourite_charts: null
      };

    case FETCHING_RESPONSE_COMMENTS:
      return {
        ...state,
        fetching_comments: true
      };

    case FETCHING_RESPONSE_COMMENTS_SUCCESS:
      return {
        ...state,
        fetching_comments: false,
        comments: action.data
      };
    case FETCHING_RESPONSE_COMMENTS_FAILED:
      return {
        ...state,
        fetching_comments: false,
        comments: []
      };

    case FETCHING_RESPONSE_AUDITS:
      return {
        ...state,
        fetching_audits: true
      };

    case FETCHING_RESPONSE_AUDITS_SUCCESS:
      return {
        ...state,
        fetching_audits: false,
        audits: action.data
      };
    case FETCHING_RESPONSE_AUDITS_FAILED:
      return {
        ...state,
        fetching_audits: false,
        audits: []
      };

    case SETTING_RESPONSE_FLAG_SUCCESS:
      return {
        ...state,
        comments: action.data.comment
          ? [action.data.comment, ...state.comments]
          : [...state.comments]
      };

    case DOWNLOADING_RESPONSE_IMAGES:
      return {
        ...state,
        donwloading_res_images: true,
        showAppInfoCard: true,
        AppInfoCard: 'Downloading response images',
        download_later: false
      };

    case TOGGLE_DOWNLOAD_LATER_MODAL:
      return {
        ...state,
        download_later: false
      };

    case DOWNLOADING_RESPONSE_IMAGES_FAILED:
      return {
        ...state,
        donwloading_res_images: false,
        showAppInfoCard: false,
        AppInfoCard: '',
        download_later: false
      };

    case DOWNLOADING_RESPONSE_IMAGES_SUCCESS:
      return {
        ...state,
        donwloading_res_images: false,
        showAppInfoCard: false,
        AppInfoCard: '',
        download_later: action.download_later ? action.download_later : false
      };

    case FILTER_AGENTS_STATS: {
      const { filter_val } = action;
      return { ...state, filter_val };
    }
    case FILTER_SURVEY_QUESTIONS: {
      const { filter_questions } = action;
      return { ...state, filter_questions };
    }
    case FETCHING_QUESTIONS_AUDIT_TRAIL: {
      return {
        ...state,
        fetching_questionAudits: true
      };
    }
    case FETCHING_QUESTIONS_AUDIT_TRAIL_SUCCESS: {
      return {
        ...state,
        fetching_questionAudits: false,
        questionAudits: action.data
      };
    }
    case SET_ADVANCED_SEARCH_VALUE: {
      return {
        ...state,
        advancedSearchValues: action.values
      };
    }
    case DELETE_ADVANCED_SEARCH_ITEM_VALUE: {
      return {
        ...state,
        advancedSearchValues: {
          keys: state.advancedSearchValues.keys.filter(
            (item, index) => index !== action.index
          ),
          values: state.advancedSearchValues.values.filter(
            (item, index) => index !== action.index
          )
        }
      };
    }

    case FETCHING_QUESTIONS_AUDIT_TRAIL_FAILED: {
      return {
        ...state,
        fetching_questionAudits: false,
        error: action.error
      };
    }
    case FETCHING_SURVEY: {
      return {
        ...state,
        questionAudits: []
      };
    }

    case DELETE_FORM_GEOFENCE:
      return {
        ...state,
        deletingGeofence: true
      };
    case DELETE_FORM_GEOFENCE_SUCCESS:
      return {
        ...state,
        deletingGeofence: false,
        geofences: state.geofences.filter(
          geofence => geofence.uuid_ !== action.uuid
        ),
        activeGeofence: {}
      };
    case DELETE_FORM_GEOFENCE_FAILED:
      return {
        ...state,
        deletingGeofence: false,
        geoFenceError: action.error
      };

    case GET_FORM_GEOFENCES:
      return {
        ...state,
        fetchingGeofence: true
      };
    case GET_FORM_GEOFENCES_SUCCESS:
      return {
        ...state,
        fetchingGeofence: false,
        geofences: action.data
      };
    case GET_FORM_GEOFENCES_FAILED:
      return {
        ...state,
        fetchingGeofence: false,
        geoFenceError: action.error
      };

    case 'UPDATE_ACTIVE_GEOFENCE':
      return {
        ...state,
        activeGeofence: action.activeGeofence
      };
    case 'UPDATE_GEOFENCES':
      return {
        ...state,
        geofences: action.geofences
      };

    case SAVE_FORM_GEOFENCE:
      return {
        ...state,
        savingGeofence: true
      };
    case SAVE_FORM_GEOFENCE_SUCCESS:
      return {
        ...state,
        savingGeofence: false,
        geofences: [action.data, ...state.geofences]
      };
    case SAVE_FORM_GEOFENCE_FAILED:
      return {
        ...state,
        savingGeofence: false,
        geoFenceError: action.error
      };
    case UPDATE_FORM_GEOFENCE:
      return {
        ...state,
        updatingGeofence: true
      };

    case UPDATE_FORM_GEOFENCE_SUCCESS:
      return {
        ...state,
        updatingGeofence: false,
        activeGeofence: action.data,
        geofences: state.geofences.map(fence =>
          fence.uuid === action.data.uuid ? action.data : fence
        )
      };

    case UPDATE_FORM_GEOFENCE_FAILED:
      return {
        ...state,
        updatingGeofence: false,
        geoFenceError: action.error
      };
      //FORM TEMPLATES
    case FETCH_FORM_TEMPLATES : return  {
      ...state ,
      fetchingFormTemplates: true
    }
    case FETCH_FORM_TEMPLATES_SUCCESS : return {
      ...state ,
      fetchingFormTemplates: false ,
      formTemplates: action.data
    };
    case  UPDATE_FORM_TEMPLATES : return  {
      ...state ,
      formTemplates : action.data
    }
    case FETCH_FORM_TEMPLATES_ERROR : return {
      ...state ,
      fetchingFormTemplates: false
    }
    default:
      return state;
  }
};
