import React, { Component } from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { getCookie, hasP } from "../utils/helpers";
import { WEBSITE_HOME, ACCOUNT_APP_URL } from "../utils/constants";

class ProtectedRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      hasPermission: false,
      redirectUrl: "",
    };
  }

  componentWillMount() {
    const user_token = getCookie("user_token");
    const user = getCookie("user") ? JSON.parse(getCookie("user")) : {};
    this.setState({
      isAuthenticated: !!user_token,
      hasPermission: hasP("ACCESS_INSYT", user.role),
    });
  }

  render() {
    const { component: Component, ...props } = this.props;
    const { isAuthenticated, hasPermission } = this.state;
    return (
      <Route
        {...props}
        render={(props) =>
          isAuthenticated && hasPermission ? (
            <Component {...props} />
          ) : (
            <div>
              {window.location.assign(
                isAuthenticated ? ACCOUNT_APP_URL : WEBSITE_HOME
              )}
            </div>
          )
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
