import React from 'react';
import { connect } from 'react-redux';

import PaymentReceiptModal from './PaymentReceipt';
import PaymentInfo from './PaymentInfo';
import UnpaidBillModal from './UnpaidBill';
import BankDepDetails from './bankDepDetails';
import cardAttachWarning from './cardAttachWarning';

const MODAL_COMPONENTS = {
  PAYMENT_RECEIPT: PaymentReceiptModal,
  PAYMENT_INFO: PaymentInfo,
  UNPAID_BILL: UnpaidBillModal,
  BANK_DEP_DETAILS: BankDepDetails,
  CARD_ATTACH_WARNING: cardAttachWarning
};

const RootModal = ({ modalName, modalProps, modalShow }) => {
  if (!modalName) {
    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[modalName];
  return <SpecificModal {...modalProps} modalShow={modalShow} />;
};

export default connect(state => state.util)(RootModal);
